import React from "react";
import MusicIcon from "../img/musicIcon.svg";
import ArtistIcon from "../img/artistIcon.svg";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";

class WithFriendsAssignScores extends React.Component {
  state = {
    currentSongPlayersScoreByIndexRef: Array(this.props.players.length).fill(0)
  };

  functions = {
    handleInputRadioChange: (event, playerIndex, value) => {
      const playerScore = event.target.checked ? value : 0;
      let scores = this.state.currentSongPlayersScoreByIndexRef;
      scores[playerIndex] = playerScore;
      this.setState({ currentSongPlayersScoreByIndexRef: scores });
    },
    submitPlayersScore: () => {
      this.props.updatePlayersScore(
        this.state.currentSongPlayersScoreByIndexRef
      );
    }
  };

  render() {
    const { currentSongId, songs, players } = this.props;
    const { handleInputRadioChange, submitPlayersScore } = this.functions;

    return (
      <div className="FullWidth">
        <div className="WithFriendsDialogTitle FullWidth MB1">
          <span className="BebasNeue TextStyle">Alors qui a trouvé ?</span>
        </div>
        <div className="FullWidth WithFriendsAnswer MB1">
          <div className="SongAndArtist">
            <span className="AnswerIcon MR0">
              <img src={MusicIcon} alt="" aria-hidden={true} />
            </span>
            <span className="AnswerText">
              {songs[currentSongId] ? songs[currentSongId].title : ""}
            </span>
          </div>
          <div className="SongAndArtist">
            <span className="AnswerIcon MR0">
              <img src={ArtistIcon} alt="" aria-hidden={true} />
            </span>
            <span className="AnswerText">
              {songs[currentSongId] ? songs[currentSongId].artist : ""}
            </span>
          </div>
        </div>
        <div className="FullWidth">
          <Scrollbars autoHide autoHeight autoHeightMax={"50vh"}>
            <ul className="WithFriendsAssignScores">
              {players.map((player, index) => (
                <li key={"player-assign-score-" + index}>
                  <span className="PlayerName BebasNeue TextStyle MB0">
                    {player.name} :
                  </span>
                  <div className="PlayerAssignScore">
                    <label className="Label WhiteSpaceNoWrap MB0">
                      <input
                        className="InputCheckbox"
                        type="radio"
                        name={"songTypeFounded" + index}
                        defaultChecked={true}
                        onChange={event =>
                          handleInputRadioChange(event, index, 0)
                        }
                      />
                      <span className="LabelCheckbox ML0">Aucun</span>
                    </label>
                    <label className="Label WhiteSpaceNoWrap MB0">
                      <input
                        className="InputCheckbox"
                        type="radio"
                        name={"songTypeFounded" + index}
                        onChange={event =>
                          handleInputRadioChange(event, index, 1)
                        }
                      />
                      <span className="LabelCheckbox ML0">
                        Le titre ou l'artiste
                      </span>
                    </label>
                    <label className="Label WhiteSpaceNoWrap MB0">
                      <input
                        className="InputCheckbox"
                        type="radio"
                        name={"songTypeFounded" + index}
                        onChange={event =>
                          handleInputRadioChange(event, index, 3)
                        }
                      />
                      <span className="LabelCheckbox ML0">Les deux</span>
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </Scrollbars>
          <div className="FullButtons MT2">
            <button className="Button HoverStyle" onClick={submitPlayersScore}>
              <span className="BebasNeue">Valider</span>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

WithFriendsAssignScores.propTypes = {
  currentSongId: PropTypes.number.isRequired,
  songs: PropTypes.array.isRequired,
  players: PropTypes.array.isRequired,
  updatePlayersScore: PropTypes.func.isRequired
};

export default WithFriendsAssignScores;
