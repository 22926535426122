import slugify from "slugify";
import { findBestMatch } from "string-similarity";
import zxcvbn from "zxcvbn";

export const LOADER_MESSAGES = {
  signInInProgress: "Connexion en cours...",
  signUpInProgress: "Inscription en cours...",
  deletionInProgress: "Suppression en cours...",
  gameCreationInProgress: "Création de la partie...",
  gameDisconnectionInProgress: "Déconnexion de la partie...",
  rankingInProgress: "Chargement du classement..."
};

export const ALERT_MESSAGES = {
  signInSucceed: "Connexion réussie !",
  signInFailed: "Échec de la connexion !",
  signOutSucceed: "Déconnexion réussie !",
  signOutFailed: "Échec de la déconnexion !",
  signUpSucceed: "Inscription réussie !",
  signUpFailed: "Échec de l'inscription !",
  emailVerificationResendSucceed:
    "Un nouvel email de confirmation vous a été envoyé !",
  emailVerificationResendFailedfirebas:
    "Impossible d'envoyer l'email de confirmation !",
  nameUpdateSucceed: "Mise à jour de votre pseudo réussie !",
  nameUpdateFailed: "Impossible de mettre à jour votre pseudo !",
  passwordUpdateSucceed: "Mise à jour de votre mot de passe réussie !",
  passwordUpdateFailed: "Impossible de mettre à jour votre mot de passe !",
  deletionSucceed: "Votre compte a été supprimé !",
  passwordResetSucceed:
    "Votre mot de passe a été réinitialisé, consultez vos mails !",
  onlyOneRoom: "Vous ne pouvez rejoindre qu'une seule partie à la fois !",
  roomDisconnection: "Vous avez été déconnecté de la partie !"
};

export const ALERT_STATUS = {
  success: "Success",
  info: "Info",
  warning: "Warning",
  error: "Error"
};

export const MATCHING_STATUS = {
  title: "Title found",
  artist: "Artist found",
  both: "Both found",
  none: "Nothing found"
};

export const THIRTY_FIVE_SECONDS = 35000;
export const THIRTY_SECONDS = 30000;
export const FIVE_SECONDS = 5000;
export const TWO_SECONDS = 2000;

const cleanSongString = songString => {
  return songString
    .replace(/\(.+\)/gim, "")
    .replace(/\[.+]/gim, "")
    .replace(/\./gim, "")
    .replace(/\b!\b/gim, "i")
    .replace(/\$/gim, "s")
    .replace(/ø/gim, "o");
};

export const matchSongProposition = (song, songProposition) => {
  const slugifyOptions = { replacement: " ", lower: true };
  const songTitle = slugify(cleanSongString(song.title), slugifyOptions);
  const songArtist = slugify(cleanSongString(song.artist), slugifyOptions);
  songProposition = slugify(songProposition, slugifyOptions);
  const matches = findBestMatch(songProposition, [
    songTitle,
    songArtist,
    songTitle + " " + songArtist
  ]);
  const minScore = songTitle.length + songArtist.length >= 16 ? 0.66 : 0.75;
  if (matches.bestMatch.rating > minScore) {
    switch (matches.bestMatchIndex) {
      case 0:
        return MATCHING_STATUS.title;
      case 1:
        return MATCHING_STATUS.artist;
      case 2:
        return MATCHING_STATUS.both;
      default:
        return MATCHING_STATUS.none;
    }
  } else {
    return MATCHING_STATUS.none;
  }
};

export const playAudioWithFadeSoundEffect = (
  audio,
  songStartTimestamp,
  fadingDelayInMs,
  setTimeRemainingIndicatorCallback,
  nextSongCallback
) => {
  const volumeIncrementValue = 0.1;
  audio.volume = 0;
  audio.play().then(playing => {
    const currentTimestamp = newTimestamp();
    const currentTime = fixCurrentAudioCurrentTime(
      currentTimestamp,
      songStartTimestamp
    );
    setTimeRemainingIndicatorCallback(30, currentTime);
    audio.currentTime = currentTime;
    const audioInterval = setInterval(() => {
      if (audio.currentTime <= currentTime + 5) {
        audio.volume += audio.volume <= 0.9 ? volumeIncrementValue : 0;
      } else if (audio.currentTime >= 25) {
        audio.volume -= audio.volume >= 0.1 ? volumeIncrementValue : 0;
      } else {
        audio.volume = 1;
      }
    }, fadingDelayInMs / 10);
    const audioTimeout = setTimeout(() => {
      clearInterval(audioInterval);
      clearTimeout(audioTimeout);
      audio.pause();
      nextSongCallback();
    }, 30000 - currentTime * 1000);
  });
};

export const convertPlayersObjectToArray = players => {
  let playersArray = [];
  for (let [key, value] of Object.entries(players)) {
    const playerId = key;
    const playerName = value.name;
    let playerScore = value.score;
    let isConnected = value.isConnected;
    if (isConnected) {
      playersArray.push({
        id: playerId,
        name: playerName,
        score: playerScore
      });
    }
  }
  playersArray.sort((a, b) => {
    return b.score - a.score;
  });
  return playersArray;
};

export const newTimestamp = (inSeconds = false) => {
  return !inSeconds ? Date.now() : Math.round(Date.now() / 1000);
};

export const determineSongsStartTimestamp = (
  songsArray,
  gameStartTimestamp
) => {
  return songsArray.map((song, index) => {
    return gameStartTimestamp + 10000 + index * 35000;
  });
};

export const determineGameEndTimestamp = (songsArray, gameStartTimestamp) => {
  return gameStartTimestamp + 10000 + songsArray.length * 35000;
};

export const fixCurrentAudioCurrentTime = (
  currentTimestamp,
  supposedTimestamp
) => {
  const value = (currentTimestamp - supposedTimestamp) / 1000;
  return Math.abs(Math.round((value + Number.EPSILON) * 100) / 100);
};

export const stopAudio = audio => {
  if (audio) {
    audio.volume = 0;
    audio.currentTime = audio.duration;
  }
};

export const convertUserScoresObjectToArray = userScores => {
  let userScoresArray = [];
  for (let [key, value] of Object.entries(userScores)) {
    userScoresArray.push({
      roomId: key,
      global: value.global ? value.global : 0,
      monthly: value.monthly ? value.monthly : 0,
      weekly: value.weekly ? value.weekly : 0,
      best: value.best ? value.best : 0
    });
  }
  return userScoresArray;
};

export const convertCamelCaseStringToClassicString = camelCaseString => {
  return camelCaseString
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, str => str.toUpperCase());
};

export const passwordStrength = password => {
  const passwordRequirements = /^(?=.*\d)(?=.*[a-z]).{6,100}$/;
  return password.match(passwordRequirements) ? zxcvbn(password).score : 0;
};

export const capitalize = s => s.charAt(0).toUpperCase() + s.slice(1);

export const handleSongPropositionMatchesUI = (
  titleScore,
  artistScore,
  matchingResult,
  uiAudios
) => {
  if (matchingResult === MATCHING_STATUS.artist && !artistScore) {
    uiAudios.oneMatch.play();
    return { input: "bounceIn", title: "", artist: "fadeIn" };
  } else if (matchingResult === MATCHING_STATUS.title && !titleScore) {
    uiAudios.oneMatch.play();
    return { input: "bounceIn", title: "fadeIn", artist: "" };
  } else if (
    matchingResult === MATCHING_STATUS.both &&
    (!titleScore || !artistScore)
  ) {
    uiAudios.twoMatches.play();
    return { input: "tada", title: "fadeIn", artist: "fadeIn" };
  } else if (
    matchingResult === MATCHING_STATUS.none &&
    (!titleScore || !artistScore)
  ) {
    uiAudios.noMatch.play();
    return { input: "shake", title: "", artist: "" };
  } else {
    return { input: "", title: "", artist: "" };
  }
};

export const determinePlayerBestScore = (userScores, currentScore, roomId) => {
  if (userScores && userScores[roomId] && userScores[roomId].best) {
    return userScores[roomId].best >= currentScore
      ? userScores[roomId].best
      : currentScore;
  } else {
    return currentScore;
  }
};

export const currentDateToYYYYMMDD = () => {
  const date = new Date();
  const mm = date.getMonth() + 1; // getMonth() is zero-based
  const dd = date.getDate();
  return [
    date.getFullYear(),
    (mm > 9 ? "" : "0") + mm,
    (dd > 9 ? "" : "0") + dd
  ].join("");
};
