import React from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { firebaseApp, firestore } from "../firebase/init";
import {
  getRoom,
  initialSongPropositionMatchesClass,
  roomInitialState,
  roomUpdater
} from "../firebase/room";
import { userUpdater } from "../firebase/user";
import {
  convertPlayersObjectToArray,
  determineSongsStartTimestamp,
  LOADER_MESSAGES,
  MATCHING_STATUS,
  matchSongProposition,
  newTimestamp,
  playAudioWithFadeSoundEffect,
  stopAudio,
  THIRTY_FIVE_SECONDS,
  THIRTY_SECONDS,
  FIVE_SECONDS,
  TWO_SECONDS,
  ALERT_MESSAGES,
  ALERT_STATUS,
  handleSongPropositionMatchesUI,
  determinePlayerBestScore,
  capitalize
} from "../helpers/utils";
import isEqual from "lodash.isequal";
import { ROOMS } from "../firebase/room";
import GameResult from "./GameResult";
import canAutoplay from "can-autoplay";
import GameLoader from "./GameLoader";
import GameAutoplay from "./GameAutoplay";
import GamePlayer from "./GamePlayer";
import GameSong from "./GameSong";
import MusicIcon from "../img/musicIcon.svg";
import ArtistIcon from "../img/artistIcon.svg";
import Dialog from "./Dialog";
import { Scrollbars } from "react-custom-scrollbars";
import {
  updatePageVisitedStatistic,
  updateRoomEndStatistic,
  updateRoomStartStatistic
} from "../firebase/statistic";

class Room extends React.Component {
  state = {
    ...roomInitialState,
    roomId: ROOMS.includes(this.props.match.params.roomId)
      ? this.props.match.params.roomId
      : "partieRapide"
  };

  functions = {
    initRoom: () => {
      updateRoomStartStatistic(
        this.state.roomId,
        this.props.currentUserId,
        this.props.currentUser
      );
      getRoom(
        this.state.roomId,
        this.props.currentUserId,
        this.props.currentUser.displayName,
        this.state.score
      ).then(room => {
        if (!room) {
          this.props.handleLoader(true, LOADER_MESSAGES.gameCreationInProgress);
          this.initRoomTimeout = setTimeout(this.functions.initRoom, 2000);
        } else {
          this.props.handleLoader(false);
          userUpdater(this.props.currentUserId, {
            currentRoom: this.state.roomId
          }).then(() => {
            const allSongsStartTimestamp = determineSongsStartTimestamp(
              room.songs,
              room.gameStartTimestamp
            );
            canAutoplay.audio().then(async ({ result }) => {
              let songs = [];
              let audios = [];
              await Promise.all(
                room.songs.map(song => {
                  return fetch(song.preview)
                    .then(() => {
                      return { song: song, audio: new Audio(song.preview) };
                    })
                    .catch(() => {
                      return null;
                    });
                })
              ).then(tracksArray => {
                tracksArray.forEach(track => {
                  if (track) {
                    songs.push(track.song);
                    audios.push(track.audio);
                  }
                });
              });
              this.setState(
                (state, props) => {
                  return {
                    currentSongId: room.currentSongId,
                    score: room.players[props.currentUserId].score,
                    players: room.players,
                    songs: songs,
                    allSongsStartTimestamp: allSongsStartTimestamp,
                    gameStartTimestamp: room.gameStartTimestamp,
                    isAllowedToPlay: result,
                    gameLoader: result !== false,
                    audios: audios,
                    roomListening: true
                  };
                },
                () => {
                  this.functions.roomListener(this.state.roomId);
                  if (this.state.isAllowedToPlay) {
                    Object.values(this.state.uiAudios).forEach(audio => {
                      audio.load();
                    });
                    this.state.audios.forEach(audio => {
                      audio.load();
                    });
                    this.functions.allowedToPlay();
                  }
                }
              );
            });
          });
        }
      });
    },
    initNewRoom: () => {
      this.props.handleLoader(true, LOADER_MESSAGES.gameCreationInProgress);
      this.setState(
        {
          ...roomInitialState,
          roomId: ROOMS.includes(this.props.match.params.roomId)
            ? this.props.match.params.roomId
            : "partieRapide"
        },
        () => {
          this.functions.initRoom();
        }
      );
    },
    componentCleanup: () => {
      if (this.state.roomListening) {
        this.unsubscribeRoomListener();
      }
      clearInterval(this.initRoomTimeout);
      clearInterval(this.timeRemainingIndicatorInterval);
      clearTimeout(this.newSongTimeout);
      clearTimeout(this.nextSongTimeout);
      clearTimeout(this.resetSongPropositionMatchesClassTimeout);
      stopAudio(this.state.currentAudio);
      this.props.handleLoader(
        false,
        LOADER_MESSAGES.gameDisconnectionInProgress
      );
      userUpdater(this.props.currentUserId, {
        currentRoom: null
      });
    },
    verifyAutoplay: () => {
      Object.values(this.state.uiAudios).forEach(audio => {
        audio.load();
      });
      this.state.audios.forEach(audio => {
        audio.load();
      });
      this.functions.allowedToPlay();
    },
    allowedToPlay: () => {
      const currentTimestamp = newTimestamp();
      const currentSongId = this.state.currentSongId;
      const allSongsStartTimestamp = this.state.allSongsStartTimestamp;
      const lastSongStartTimestamp =
        allSongsStartTimestamp[allSongsStartTimestamp.length - 1];
      if (currentTimestamp >= lastSongStartTimestamp + THIRTY_SECONDS) {
        this.functions.endGameBehavior(0);
      } else if (currentTimestamp >= lastSongStartTimestamp) {
        const lastSongId = allSongsStartTimestamp.length - 1;
        this.functions.firstPlaySong(lastSongId, 0);
      } else {
        for (let id = currentSongId; id < allSongsStartTimestamp.length; id++) {
          if (id === 0 && currentTimestamp < allSongsStartTimestamp[id]) {
            const timeout = allSongsStartTimestamp[id] - currentTimestamp;
            this.functions.firstPlaySong(id, timeout);
            break;
          } else if (
            currentTimestamp > allSongsStartTimestamp[id] - FIVE_SECONDS &&
            currentTimestamp < allSongsStartTimestamp[id]
          ) {
            const timeout = allSongsStartTimestamp[id] - currentTimestamp;
            this.functions.firstPlaySong(id, timeout);
            break;
          } else if (
            currentTimestamp <
            allSongsStartTimestamp[id] + THIRTY_SECONDS
          ) {
            this.functions.firstPlaySong(id, 0);
            break;
          }
        }
      }
    },
    firstPlaySong: (songId, timeout) => {
      this.setState(
        {
          gameLoader: timeout >= TWO_SECONDS,
          currentSongId: songId,
          isAllowedToPlay: true
        },
        () => {
          if (timeout !== 0) {
            this.newSongTimeout = setTimeout(this.functions.playSong, timeout);
          } else {
            this.functions.playSong();
          }
        }
      );
    },
    playSong: () => {
      const currentSongId = this.state.currentSongId;
      if (this.state.songs[currentSongId]) {
        const currentAudio = this.state.audios[currentSongId];
        const songStartTimestamp = this.state.allSongsStartTimestamp[
          currentSongId
        ];
        this.setState(
          {
            gameLoader: false,
            currentAudio: currentAudio
          },
          () => {
            clearInterval(this.timeRemainingIndicatorInterval);
            playAudioWithFadeSoundEffect(
              currentAudio,
              songStartTimestamp,
              FIVE_SECONDS,
              this.functions.setTimeRemainingIndicator,
              this.functions.nextSong
            );
          }
        );
      }
    },
    nextSong: () => {
      const currentSongId = this.state.currentSongId;
      this.setState(
        state => {
          return {
            currentSongId: state.currentSongId + 1,
            currentAudio: null,
            titleScore: null,
            artistScore: null
          };
        },
        () => {
          if (this.state.songs[currentSongId + 1]) {
            this.nextSongTimeout = setTimeout(
              this.functions.playSong,
              FIVE_SECONDS
            );
            roomUpdater(this.state.roomId, {
              currentSongId: this.state.currentSongId
            });
          } else {
            this.functions.endGameBehavior();
          }
        }
      );
    },
    setTimeRemainingIndicator: (maxSeconds, currentTime) => {
      this.setState(
        { timeRemainingIndicator: Math.round(maxSeconds - currentTime) },
        () => {
          this.timeRemainingIndicatorInterval = setInterval(() => {
            if (this.state.timeRemainingIndicator > 0) {
              this.setState(state => ({
                timeRemainingIndicator: state.timeRemainingIndicator - 1
              }));
            } else {
              clearInterval(this.timeRemainingIndicatorInterval);
              this.setState({ timeRemainingIndicator: null });
            }
          }, 1000);
        }
      );
    },
    submitSongProposition: (event, songProposition, currentSongId) => {
      event.preventDefault();
      const currentSong = this.state.songs[currentSongId];
      const songPropositionTimestamp = newTimestamp();
      const songScore = Math.floor(
        (THIRTY_FIVE_SECONDS -
          (songPropositionTimestamp -
            this.state.allSongsStartTimestamp[currentSongId])) /
          1000
      );
      const matchingResult = matchSongProposition(currentSong, songProposition);
      this.functions.setArtistAndTitleScoresState(songScore, matchingResult);
    },
    setArtistAndTitleScoresState: (songScore, matchingResult) => {
      clearTimeout(this.resetSongPropositionMatchesClassTimeout);
      if (this.state.currentAudio) {
        this.setState(
          state => ({
            titleScore:
              !state.titleScore &&
              (matchingResult === MATCHING_STATUS.title ||
                matchingResult === MATCHING_STATUS.both)
                ? state.titleScore + songScore
                : state.titleScore,
            artistScore:
              !state.artistScore &&
              (matchingResult === MATCHING_STATUS.artist ||
                matchingResult === MATCHING_STATUS.both)
                ? state.artistScore + songScore
                : state.artistScore,
            songPropositionMatchesClass: handleSongPropositionMatchesUI(
              state.titleScore,
              state.artistScore,
              matchingResult,
              state.uiAudios
            ),
            songInputValue: ""
          }),
          () => {
            this.resetSongPropositionMatchesClassTimeout = setTimeout(() => {
              this.setState({
                songPropositionMatchesClass: initialSongPropositionMatchesClass
              });
            }, 1000);
          }
        );
      }
    },
    handleSongPropositionChange: event => {
      this.setState({
        songInputValue: event.target.value,
        songPropositionMatchesClass: initialSongPropositionMatchesClass
      });
    },
    updateScore: increment => {
      const userBestScore =
        this.props.currentUser["scores"] &&
        this.props.currentUser["scores"][this.state.roomId] &&
        this.props.currentUser["scores"][this.state.roomId].best
          ? this.props.currentUser["scores"][this.state.roomId].best
          : 0;
      this.setState(
        (state, props) => ({
          score: state.score + increment,
          newRecord: state.score + increment > userBestScore,
          players: {
            ...state.players,
            [props.currentUserId]: {
              ...state.players[props.currentUserId],
              score: state.score + increment
            }
          }
        }),
        () => {
          roomUpdater(this.state.roomId, {
            ["players." + this.props.currentUserId + ".score"]: this.state.score
          });
        }
      );
    },
    endGameBehavior: (timeout = TWO_SECONDS) => {
      this.unsubscribeRoomListener();
      roomUpdater(this.state.roomId, {
        isGameIsOver: true
      }).then(response => {
        setTimeout(() => {
          updateRoomEndStatistic(
            this.state.roomId,
            this.props.currentUserId,
            this.props.currentUser
          );
          this.setState({ displayResult: true });
        }, timeout);
      });
      userUpdater(this.props.currentUserId, {
        ["scores." +
        this.state.roomId +
        ".weekly"]: firebaseApp.firestore.FieldValue.increment(
          this.state.score
        ),
        ["scores." +
        this.state.roomId +
        ".monthly"]: firebaseApp.firestore.FieldValue.increment(
          this.state.score
        ),
        ["scores." +
        this.state.roomId +
        ".global"]: firebaseApp.firestore.FieldValue.increment(
          this.state.score
        ),
        ["scores." + this.state.roomId + ".best"]: determinePlayerBestScore(
          this.props.currentUser["scores"],
          this.state.score,
          this.state.roomId
        )
      });
    },
    roomListener: roomId => {
      this.unsubscribeRoomListener = firestore
        .collection("rooms")
        .doc(roomId)
        .onSnapshot(doc => {
          if (doc.exists) {
            const isServer = !doc.metadata.hasPendingWrites;
            const data = doc.data();
            const playersWereUpdated = !isEqual(
              data.players,
              this.state.players
            );
            if (isServer && playersWereUpdated) {
              this.setState({
                players: data.players
              });
            }
          }
        });
    }
  };

  componentDidMount() {
    updatePageVisitedStatistic(this.props.slug + capitalize(this.state.roomId));
    window.addEventListener("beforeunload", this.functions.componentCleanup);
    this.functions.initRoom();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.currentUser.currentRoom !==
        prevProps.currentUser.currentRoom &&
      this.props.currentUser.currentRoom !== null &&
      this.props.currentUser.currentRoom !== this.state.roomId
    ) {
      this.props.handleAlert(ALERT_MESSAGES.onlyOneRoom, ALERT_STATUS.warning);
      this.props.history.push("/");
    }
    if (
      this.state.players[this.props.currentUserId] &&
      !this.state.players[this.props.currentUserId].isConnected
    ) {
      this.props.handleAlert(
        ALERT_MESSAGES.roomDisconnection,
        ALERT_STATUS.warning
      );
      this.props.history.push("/");
    }
    if (
      this.state.titleScore !== prevState.titleScore &&
      this.state.titleScore
    ) {
      this.functions.updateScore(this.state.titleScore);
    }
    if (
      this.state.artistScore !== prevState.artistScore &&
      this.state.artistScore
    ) {
      this.functions.updateScore(this.state.artistScore);
    }
  }

  componentWillUnmount() {
    this.functions.componentCleanup();
    window.removeEventListener("beforeunload", this.functions.componentCleanup);
  }

  render() {
    const {
      songInputValue,
      currentSongId,
      players,
      newRecord,
      songs,
      artistScore,
      titleScore,
      songPropositionMatchesClass,
      isAllowedToPlay,
      timeRemainingIndicator,
      displayResult,
      gameLoader
    } = this.state;
    const {
      submitSongProposition,
      handleSongPropositionChange,
      verifyAutoplay,
      initNewRoom
    } = this.functions;

    let alreadyPlayedSongsArray = [];
    songs.forEach((song, index) => {
      if (index < currentSongId) {
        alreadyPlayedSongsArray.push(song);
      }
    });

    let playersArray = convertPlayersObjectToArray(players);

    return (
      <div className="Wrapper">
        <Dialog show={!isAllowedToPlay} size="Small">
          <GameAutoplay verifyAutoplay={verifyAutoplay} />
        </Dialog>
        <Dialog show={gameLoader} size="Small">
          <GameLoader />
        </Dialog>
        <Dialog show={displayResult} size="Medium">
          <GameResult
            players={players}
            newRecord={newRecord}
            replayAction={initNewRoom}
          />
        </Dialog>
        <div className="RoomTop">
          <form
            action="#"
            className="RoomForm"
            onSubmit={event =>
              submitSongProposition(event, songInputValue, currentSongId)
            }
          >
            <label
              className={`Label animated ${songPropositionMatchesClass.input}`}
            >
              <span className="LabelText BebasNeue">Titre et/ou Artiste</span>
              <input
                className="InputText"
                type="text"
                autoComplete="off"
                autoCorrect="off"
                spellCheck={false}
                value={songInputValue}
                onChange={event => handleSongPropositionChange(event)}
              />
            </label>
            <button className="Button HoverStyle MT1" type="submit">
              <span className="BebasNeue">Valider</span>
            </button>
          </form>
          <div className="TimeRemaining">
            <span className="UbuntuMono">{timeRemainingIndicator}</span>
          </div>
          <div className="RoomAnswer">
            <div
              className={`SongAndArtist ${titleScore ? "Found" : ""} animated ${
                songPropositionMatchesClass.title
              }`}
            >
              <span className="AnswerIcon MR0">
                <img src={MusicIcon} alt="" aria-hidden={true} />
              </span>
              <span className="AnswerText">
                {titleScore ? songs[currentSongId].title : ""}
              </span>
            </div>
            <div
              className={`SongAndArtist MT1 ${
                artistScore ? "Found" : ""
              } animated ${songPropositionMatchesClass.artist}`}
            >
              <span className="AnswerIcon MR0">
                <img src={ArtistIcon} alt="" aria-hidden={true} />
              </span>
              <span className="AnswerText">
                {artistScore ? songs[currentSongId].artist : ""}
              </span>
            </div>
          </div>
        </div>
        <div className="RoomResults MT2 MXAuto">
          <div className="RoomResultsBlocks">
            <Scrollbars autoHide autoHeight autoHeightMax={"100vh"}>
              <div className="RoomMusics">
                <label htmlFor="SongsList" className="ListLabel MB2">
                  <span className="TextStyle BebasNeue">
                    Musiques {`(${currentSongId}/${songs.length})`}
                  </span>
                </label>
                <ul id="SongsList" className="RoomSongs">
                  {alreadyPlayedSongsArray.reverse().map((song, index) => (
                    <GameSong key={"song-" + index} song={song} />
                  ))}
                </ul>
              </div>
            </Scrollbars>
          </div>
          <div className="RoomResultsBlocks">
            <Scrollbars autoHide autoHeight autoHeightMax={"100vh"}>
              <div className="RoomRanking">
                <label htmlFor="RankingList" className="ListLabel MB2">
                  <span className="TextStyle BebasNeue">Classement</span>
                </label>
                <ol id="RankingList" className="RoomPlayers">
                  {playersArray.map((player, index) => (
                    <GamePlayer
                      key={"player-" + index}
                      player={player}
                      position={index}
                    />
                  ))}
                </ol>
              </div>
            </Scrollbars>
          </div>
        </div>
        <Link to="/">
          <button className="Button HoverStyle MT2">
            <span className="BebasNeue">Quitter la partie</span>
          </button>
        </Link>
      </div>
    );
  }
}

Room.propTypes = {
  currentUser: PropTypes.object.isRequired,
  currentUserId: PropTypes.string.isRequired,
  handleLoader: PropTypes.func.isRequired,
  handleAlert: PropTypes.func.isRequired,
  slug: PropTypes.string.isRequired
};

export default withRouter(Room);
