import React from "react";
import AutoplaySvg from "../img/autoplay.svg";
import { ReactSVG } from "react-svg";
import PropTypes from "prop-types";

class GameAutoplay extends React.Component {
  render() {
    const { verifyAutoplay } = this.props;

    return (
      <div className="RoomAutoplayVerification">
        <ReactSVG src={AutoplaySvg} className="RoomSvg MB1" />
        <p>Pour jouer, tu dois autoriser la lecture automatique des sons.</p>
        <button className="Button HoverStyle" onClick={verifyAutoplay}>
          <span className="BebasNeue">Ok, évidemment !</span>
        </button>
      </div>
    );
  }
}

GameAutoplay.propTypes = {
  verifyAutoplay: PropTypes.func.isRequired
};

export default GameAutoplay;
