import React from "react";
import { withRouter } from "react-router-dom";
import { signInAnonymousUser, updateUserName } from "../firebase/user";
import {
  ALERT_MESSAGES,
  ALERT_STATUS,
  LOADER_MESSAGES
} from "../helpers/utils";
import lottie from "lottie-web";
import connectionLogo from "../data/connection-logo";
import PropTypes from "prop-types";
import { updatePageVisitedStatistic } from "../firebase/statistic";

class Connection extends React.Component {
  state = {
    userNameInputValue: this.props.currentUser
      ? this.props.currentUser.displayName
      : "",
    currentUserName: this.props.currentUser
      ? this.props.currentUser.displayName
      : ""
  };

  functions = {
    submitUserName: (event, userName) => {
      event.preventDefault();
      userName = userName.replace(/\s/g, "");
      if (this.state.currentUserName !== userName) {
        this.props.handleLoader(true, LOADER_MESSAGES.signInInProgress);
        if (!this.props.currentUser) {
          return signInAnonymousUser(userName).then(response => {
            if (response.success) {
              this.props.handleAlert(
                ALERT_MESSAGES.signInSucceed,
                ALERT_STATUS.success
              );
              this.props.history.push("/");
            } else {
              this.props.handleAlert(
                ALERT_MESSAGES.signInFailed,
                ALERT_STATUS.error
              );
            }
            this.props.handleLoader(false);
          });
        } else {
          return updateUserName(this.props.currentUserId, userName).then(
            response => {
              if (response.success) {
                this.props.handleAlert(
                  ALERT_MESSAGES.nameUpdateSucceed,
                  ALERT_STATUS.success
                );
                this.props.history.push("/");
              } else {
                this.props.handleAlert(
                  ALERT_MESSAGES.nameUpdateFailed,
                  ALERT_STATUS.error
                );
              }
              this.props.handleLoader(false);
            }
          );
        }
      } else {
        this.props.history.push("/");
      }
    },
    handleInputChange: event => {
      const target = event.target;
      const name = target.name;
      const value = target.value;
      this.setState({ [name]: value });
    }
  };

  componentDidMount() {
    setTimeout(
      () => {
        lottie.loadAnimation({
          container: this.connectionLogoContainer,
          renderer: "svg",
          loop: false,
          autoplay: true,
          animationData: connectionLogo
        });
      },
      this.props.loaderDisplaying ? 1500 : 0
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.currentUser !== prevProps.currentUser) {
      if (this.props.currentUser) {
        updatePageVisitedStatistic(this.props.slug);
      }
      this.setState({
        userNameInputValue: this.props.currentUser
          ? this.props.currentUser.displayName
          : "",
        currentUserName: this.props.currentUser
          ? this.props.currentUser.displayName
          : ""
      });
    }
  }

  render() {
    const { userNameInputValue } = this.state;
    const { submitUserName, handleInputChange } = this.functions;

    return (
      <div className="Wrapper">
        <h1 className="MainTitle">
          <div
            title="That's my Jam"
            className="ConnectionLogo MXAuto"
            ref={ref => (this.connectionLogoContainer = ref)}
          />
          <span className="TextStyle BebasNeue">
            Le meilleur blind&nbsp;test&nbsp;musical
            <br />
            online et gratuit&nbsp;!
          </span>
        </h1>
        <form
          className="StartForm MXAuto MT1"
          onSubmit={event => submitUserName(event, userNameInputValue)}
        >
          <label className="Label">
            <span className="LabelText BebasNeue">Pseudo</span>
            <input
              className="InputText"
              name="userNameInputValue"
              type="text"
              value={userNameInputValue || ""}
              onChange={event => handleInputChange(event)}
              required
            />
          </label>
          <button className="Button HoverStyle ML1" type="submit">
            <span className="BebasNeue">Jouer</span>
          </button>
        </form>
      </div>
    );
  }
}

Connection.propTypes = {
  currentUser: PropTypes.object,
  currentUserId: PropTypes.string,
  loaderDisplaying: PropTypes.bool.isRequired,
  handleLoader: PropTypes.func.isRequired,
  handleAlert: PropTypes.func.isRequired,
  slug: PropTypes.string.isRequired
};

export default withRouter(Connection);
