import React from "react";
import PropTypes from "prop-types";

class Alert extends React.Component {
  render() {
    const { message, type } = this.props;
    return (
      <div className={`Alert ${type}`}>
        <span>{message}</span>
      </div>
    );
  }
}

Alert.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

export default Alert;
