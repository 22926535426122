import { firebaseApp, firestore } from "./init";
import { currentDateToYYYYMMDD } from "../helpers/utils";

const statisticsCollection = firestore.collection("statistics");

export const setStatistic = (dateYYYYMMDD, statData) => {
  if (process.env.NODE_ENV !== "development") {
    const dateYYYYMM = dateYYYYMMDD.slice(0, -2);
    return statisticsCollection
      .doc(dateYYYYMMDD)
      .set(statData, { merge: true })
      .then(() => {
        return statisticsCollection
          .doc(dateYYYYMM)
          .set(statData, { merge: true })
          .then(() => null)
          .catch(error => {
            console.error("Error : ", error);
          });
      })
      .catch(error => {
        console.error("Error : ", error);
      });
  }
  return null;
};

export const updatePageVisitedStatistic = pageSlug => {
  setStatistic(currentDateToYYYYMMDD(), {
    global: {
      pagesVisited: {
        [pageSlug]: firebaseApp.firestore.FieldValue.increment(1)
      }
    }
  });
};

export const updateAuthenticatedUserStatistic = rawUser => {
  setStatistic(currentDateToYYYYMMDD(), {
    global: {
      authenticatedUsers: {
        allConnectionsCount: firebaseApp.firestore.FieldValue.increment(1),
        [rawUser.uid]: {
          name: rawUser.displayName,
          connectionCount: firebaseApp.firestore.FieldValue.increment(1)
        }
      }
    }
  });
};

export const updateRoomStartStatistic = (roomId, userId, user) => {
  setStatistic(currentDateToYYYYMMDD(), {
    [roomId]: {
      usersWhoHavePlayedCount: firebaseApp.firestore.FieldValue.increment(1),
      users: {
        [userId]: {
          name: user.displayName,
          gamesPlayed: firebaseApp.firestore.FieldValue.increment(1)
        }
      }
    }
  });
};

export const updateRoomEndStatistic = (roomId, userId, user) => {
  setStatistic(currentDateToYYYYMMDD(), {
    [roomId]: {
      usersWhoHaveCompletedCount: firebaseApp.firestore.FieldValue.increment(1),
      users: {
        [userId]: {
          name: user.displayName,
          gamesCompleted: firebaseApp.firestore.FieldValue.increment(1)
        }
      }
    }
  });
};

export const updateWithFriendsStartStatistic = (
  userId,
  numberOfFriends,
  selectedPlaylist
) => {
  setStatistic(currentDateToYYYYMMDD(), {
    withFriends: {
      allGamesPlayed: firebaseApp.firestore.FieldValue.increment(1),
      [userId]: {
        gamesPlayed: firebaseApp.firestore.FieldValue.increment(1),
        games: firebaseApp.firestore.FieldValue.arrayUnion({
          selectedPlaylist: selectedPlaylist,
          numberOfFriends: numberOfFriends
        })
      }
    }
  });
};

export const updateWithFriendsEndStatistic = userId => {
  setStatistic(currentDateToYYYYMMDD(), {
    withFriends: {
      allGamesCompleted: firebaseApp.firestore.FieldValue.increment(1),
      [userId]: {
        gamesCompleted: firebaseApp.firestore.FieldValue.increment(1)
      }
    }
  });
};
