import React from "react";
import PropTypes from "prop-types";
import { convertCamelCaseStringToClassicString } from "../helpers/utils";
import { ROOMS } from "../firebase/room";
import { Scrollbars } from "react-custom-scrollbars";

class WithFriendsInit extends React.Component {
  state = {
    playlistsName: ROOMS
  };

  render() {
    const { playlistsName } = this.state;
    const {
      launchStep,
      players,
      playlistId,
      leaveGame,
      changeLaunchStep,
      changePlayersNumber,
      changePlayerName,
      selectPlaylist,
      launchGame
    } = this.props;

    return (
      <div className="FullWidth">
        <div className="WithFriendsDialogTitle FullWidth">
          <span className="BebasNeue TextStyle">Entre potes</span>
        </div>
        {launchStep === 0 && (
          <div className="FullWidth">
            <p>
              Ce mode de jeu permet de jouer à plusieurs sur le même appareil
              (il est conseillé de jouer sur ordinateur ou de connecter votre
              téléphone à une enceinte).
            </p>
            <p>
              Vous allez saisir le nom de chacun de vos amis et ensuite le type
              de playlist avec laquelle vous souhaitez jouer.
            </p>
            <p>
              A la fin de chaque chanson vous devrez sélectionner quel(s)
              joueur(s) a/ont trouvé le titre et/ou l'artiste.
            </p>
            <p>
              Trouver le titre ou l'artiste rapporte 1 point et trouver les deux
              rapporte 3 points.
            </p>
            <p>
              Ici vous ne devez pas saisir la réponse, nous vous laissons
              valider ou invalider les réponses entre vous, comme bon vous
              semble, vous êtes vos propres juges !
            </p>
            <p>
              Dans ce mode de jeu les points ne sont pas comptabilisés sur votre
              compte.
            </p>
            <div className="FullButtons">
              <button className="Button HoverStyle" onClick={leaveGame}>
                <span className="BebasNeue">Quitter</span>
              </button>
              <button
                className="Button HoverStyle ML2"
                onClick={event => changeLaunchStep(event, 1)}
              >
                <span className="BebasNeue">Suivant</span>
              </button>
            </div>
          </div>
        )}
        {launchStep === 1 && (
          <form
            action="#"
            className="FullWidth MT1"
            onSubmit={event => changeLaunchStep(event, 2)}
          >
            <label className="Label MB1">
              <span className="LabelText BebasNeue">Nombre de joueurs</span>
              <span className="SelectWrapper">
                <select
                  className="Select"
                  name="selectPlayersNumber"
                  defaultValue={players.length}
                  onChange={event => changePlayersNumber(event)}
                >
                  {[...Array(20)].map((empty, index) => {
                    return (
                      <option
                        key={`player-number-option-${index}`}
                        value={index + 1}
                      >
                        {index + 1}
                      </option>
                    );
                  })}
                </select>
              </span>
            </label>
            <Scrollbars autoHide autoHeight autoHeightMax={"50vh"}>
              <div className="WithFriendsInputs">
                {players.map((player, index) => {
                  return (
                    <label
                      className={`Label ${
                        players.length !== index + 1 ? "MB1" : ""
                      }`}
                      key={"player-name-" + index}
                    >
                      <span className="LabelText BebasNeue">
                        Joueur {index + 1}
                      </span>
                      <input
                        className="InputText"
                        name={"playerNameInputValue" + index}
                        type="text"
                        defaultValue={players[index].name}
                        onChange={event => changePlayerName(event, index)}
                        required
                      />
                    </label>
                  );
                })}
              </div>
            </Scrollbars>
            <div className="FullButtons MT1">
              <button
                className="Button HoverStyle"
                type="button"
                onClick={event => changeLaunchStep(event, 0)}
              >
                <span className="BebasNeue">Précédent</span>
              </button>
              <button className="Button HoverStyle ML2" type="submit">
                <span className="BebasNeue">Suivant</span>
              </button>
            </div>
          </form>
        )}
        {launchStep === 2 && (
          <form
            action="#"
            className="MT1 FullWidth"
            onSubmit={event => launchGame(event)}
          >
            <label className="Label MB1">
              <span className="LabelText BebasNeue">
                Choisissez votre playlist
              </span>
              <span className="SelectWrapper">
                <select
                  className="Select"
                  name="selectPlaylist"
                  value={playlistId || "partieRapide"}
                  onChange={event => selectPlaylist(event)}
                >
                  {playlistsName.map((playlistName, index) => {
                    return (
                      <option
                        key={`playlist-option-${index}`}
                        value={playlistName}
                      >
                        {convertCamelCaseStringToClassicString(playlistName)}
                      </option>
                    );
                  })}
                </select>
              </span>
            </label>
            <div className="FullButtons MT1">
              <button
                className="Button HoverStyle"
                type="button"
                onClick={event => changeLaunchStep(event, 1)}
              >
                <span className="BebasNeue">Précédent</span>
              </button>
              <button className="Button HoverStyle ML2" type="submit">
                <span className="BebasNeue">Jouer</span>
              </button>
            </div>
          </form>
        )}
      </div>
    );
  }
}

WithFriendsInit.propTypes = {
  launchStep: PropTypes.number.isRequired,
  players: PropTypes.array.isRequired,
  playlistId: PropTypes.string.isRequired,
  leaveGame: PropTypes.func.isRequired,
  changeLaunchStep: PropTypes.func.isRequired,
  changePlayersNumber: PropTypes.func.isRequired,
  changePlayerName: PropTypes.func.isRequired,
  selectPlaylist: PropTypes.func.isRequired,
  launchGame: PropTypes.func.isRequired
};

export default WithFriendsInit;
