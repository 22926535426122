import React from "react";
import PlayersCountIndicator from "./PlayersCountIndicator";
import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

class HomeCategory extends React.Component {
  render() {
    const {
      roomPlayersCount,
      pathName,
      roomId,
      isSoon,
      isHighlighted,
      roomSvg,
      roomName,
      roomDescription
    } = this.props;

    return (
      <Link
        to={{
          pathname: pathName,
          state: {
            roomId: roomId
          }
        }}
        className={`HomeCategory ${isHighlighted ? "Highlighted" : ""} ${
          isSoon ? "Soon" : ""
        } HoverStyle MF1`}
      >
        {roomPlayersCount !== null && (
          <PlayersCountIndicator roomPlayersCount={roomPlayersCount} />
        )}
        <ReactSVG src={roomSvg} className="RoomSvg MB1" />
        <span className="RoomTitle BebasNeue">{roomName}</span>
        <span className="RoomSmallDescription Nickainley">
          {roomDescription}
        </span>
      </Link>
    );
  }
}

HomeCategory.propTypes = {
  roomPlayersCount: PropTypes.number,
  pathName: PropTypes.string.isRequired,
  roomId: PropTypes.string.isRequired,
  isSoon: PropTypes.bool.isRequired,
  isHighlighted: PropTypes.bool.isRequired,
  roomSvg: PropTypes.string.isRequired,
  roomName: PropTypes.string.isRequired,
  roomDescription: PropTypes.string.isRequired
};

export default HomeCategory;
