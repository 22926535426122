import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/firestore";
import translate from "translate";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
export const firebaseApp = firebase;
const app = firebaseApp.initializeApp(firebaseConfig);
export const fireEmailAuthProvider = firebaseApp.auth.EmailAuthProvider;
export const fireauth = app.auth();
export const firestore = app.firestore();
export const firedatabase = app.database();

// Initialize Translate
translate.engine = "google";
translate.key = firebaseConfig.apiKey;
export const googletranslate = translate;
