import { firestore } from "./init";
import sampleSize from "lodash.samplesize";

const playlistsCollection = firestore.collection("playlists");

export const getPlaylist = playlistId => {
  return playlistsCollection
    .doc(playlistId)
    .get()
    .then(rawPlaylist => {
      if (rawPlaylist.exists) {
        const playlist = rawPlaylist.data();
        return sampleSize(playlist, 10);
      }
    })
    .catch(error => {
      console.error("Error : ", error);
    });
};
