import React from "react";
import User from "../img/userIcon.svg";
import PropTypes from "prop-types";

class PlayersCountIndicator extends React.Component {
  render() {
    const { roomPlayersCount } = this.props;

    return (
      <div
        className="RoomPlayersCountIndicator"
        title="Nombre de joueur(s) dans la partie"
      >
        <span className="TextPlayerIndicator">
          {roomPlayersCount ? roomPlayersCount : 0}
        </span>
        <span className="IconPlayerIndicator">
          <img src={User} alt="" aria-hidden={true} />
        </span>
      </div>
    );
  }
}

PlayersCountIndicator.propTypes = {
  roomPlayersCount: PropTypes.number
};

export default PlayersCountIndicator;
