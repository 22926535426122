import React from "react";
import { Link, withRouter } from "react-router-dom";
import Logo from "../img/logo.svg";
import { signOutUser } from "../firebase/user";
import { ALERT_MESSAGES, ALERT_STATUS } from "../helpers/utils";
import UserIcon from "../img/userIcon.svg";
import SignIn from "../img/signInIcon.svg";
import SignOut from "../img/signOutIcon.svg";
import SignUpIcon from "../img/signUpIcon.svg";
import PropTypes from "prop-types";
import Dialog from "./Dialog";
import Authentication from "./Authentication";

class Header extends React.Component {
  state = {
    showAuthDialog: false,
    authFormType: null
  };

  functions = {
    signOut: currentUserId => {
      this.props.unsubscribeUserListener();
      signOutUser(currentUserId).then(error => {
        if (error !== null) {
          this.props.handleAlert(
            ALERT_MESSAGES.signOutFailed,
            ALERT_STATUS.error
          );
        } else {
          this.props.history.push("/connexion");
          this.props.resetCurrentUser();
          this.props.handleAlert(
            ALERT_MESSAGES.signOutSucceed,
            ALERT_STATUS.success
          );
        }
      });
    },
    handleDialogDisplay: (isVisible, authFormType = "") => {
      this.setState({ showAuthDialog: isVisible, authFormType: authFormType });
    }
  };

  render() {
    const { showAuthDialog, authFormType } = this.state;
    const {
      currentUser,
      currentUserId,
      unsubscribeUserListener,
      handleLoader,
      handleAlert
    } = this.props;
    const { signOut, handleDialogDisplay } = this.functions;

    return (
      <header className="AppHeader">
        <Link to={currentUser ? "/" : "/connexion"}>
          <img className="AppLogo" src={Logo} alt="That's My Jam" />
        </Link>
        {currentUser && currentUser.email ? (
          <div>
            <Link to="/account">
              <button className="Button HoverStyle">
                <span className="DDesktop BebasNeue">Mon compte</span>
                <span className="HeaderMobileButton DMobile">
                  <img src={UserIcon} alt="Mon compte" />
                </span>
              </button>
            </Link>
            <button
              className="Button HoverStyle ML1"
              onClick={() => signOut(currentUserId)}
            >
              <span className="DDesktop BebasNeue">Déconnexion</span>
              <span className="HeaderMobileButton DMobile">
                <img src={SignOut} alt="Déconnexion" />
              </span>
            </button>
          </div>
        ) : (
          <div>
            <button
              className="Button HoverStyle"
              onClick={() => handleDialogDisplay(true, "signIn")}
            >
              <span className="DDesktop BebasNeue">Connexion</span>
              <span className="HeaderMobileButton DMobile">
                <img src={SignIn} alt="Connexion" />
              </span>
            </button>
            <button
              className="Button HoverStyle ML1"
              onClick={() => handleDialogDisplay(true, "signUp")}
            >
              <span className="DDesktop BebasNeue">Inscription</span>
              <span className="HeaderMobileButton DMobile">
                <img src={SignUpIcon} alt="Inscription" />
              </span>
            </button>
          </div>
        )}
        <Dialog
          show={showAuthDialog}
          size="Small"
          handleDialogDisplay={handleDialogDisplay}
        >
          <Authentication
            currentUser={currentUser}
            authFormType={authFormType}
            handleDialogDisplay={handleDialogDisplay}
            handleLoader={handleLoader}
            handleAlert={handleAlert}
            unsubscribeUserListener={unsubscribeUserListener}
          />
        </Dialog>
      </header>
    );
  }
}

Header.propTypes = {
  currentUser: PropTypes.object,
  currentUserId: PropTypes.string,
  handleLoader: PropTypes.func.isRequired,
  handleAlert: PropTypes.func.isRequired,
  unsubscribeUserListener: PropTypes.func,
  resetCurrentUser: PropTypes.func.isRequired
};

export default withRouter(Header);
