import React from "react";
import { Link, withRouter } from "react-router-dom";
import update from "react-addons-update"; // ES6
import Dialog from "./Dialog";
import { getPlaylist } from "../firebase/playlists";
import canAutoplay from "can-autoplay";
import {
  FIVE_SECONDS,
  newTimestamp,
  playAudioWithFadeSoundEffect,
  stopAudio,
  TWO_SECONDS
} from "../helpers/utils";
import WithFriendsInit from "./WithFriendsInit";
import GameLoader from "./GameLoader";
import GameSong from "./GameSong";
import GamePlayer from "./GamePlayer";
import { CSSTransition } from "react-transition-group";
import WithFriendsAssignScores from "./WithFriendsAssignScores";
import GameResult from "./GameResult";
import { withFriendsInitialState } from "../firebase/room";
import GameAutoplay from "./GameAutoplay";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";
import {
  updatePageVisitedStatistic,
  updateWithFriendsEndStatistic,
  updateWithFriendsStartStatistic
} from "../firebase/statistic";

class WithFriends extends React.Component {
  state = {
    ...withFriendsInitialState,
    launchStep: 0,
    playlistId: "partieRapide",
    players: [{ name: "", score: 0 }]
  };

  functions = {
    componentCleanup: () => {
      clearInterval(this.timeRemainingIndicatorInterval);
      clearTimeout(this.initGameTimeout);
      clearTimeout(this.nextSongTimeout);
      clearTimeout(this.endGameBehaviorTimeout);
      stopAudio(this.state.currentAudio);
    },
    changeLaunchStep: (event, stepNumber) => {
      event.preventDefault();
      this.setState({ launchStep: stepNumber });
    },
    changePlayersNumber: event => {
      const playersNumber = parseInt(event.target.value);
      if (this.state.players.length >= 1 && this.state.players.length <= 20) {
        if (this.state.players.length > playersNumber) {
          this.setState(state => ({
            players: update(state.players, {
              $apply: players => {
                for (let i = players.length; i > playersNumber; i--) {
                  players.pop();
                }
                return players;
              }
            })
          }));
        } else {
          this.setState(state => ({
            players: update(state.players, {
              $apply: players => {
                for (let i = players.length; i < playersNumber; i++) {
                  players.push({ name: "", score: 0 });
                }
                return players;
              }
            })
          }));
        }
      }
    },
    changePlayerName: (event, index) => {
      const playerName = event.target.value;
      this.setState(state => ({
        players: update(state.players, {
          [index]: { name: { $set: playerName } }
        })
      }));
    },
    selectPlaylist: event => {
      const playlistId = event.target.value;
      this.setState({ playlistId: playlistId });
    },
    updatePlayersScore: currentSongPlayersScore => {
      this.setState(state => ({
        players: update(state.players, {
          $apply: players => {
            return players.map((player, index) => {
              player.score = player.score + currentSongPlayersScore[index];
              return player;
            });
          }
        })
      }));
      this.setState({ assignScores: false }, () => {
        setTimeout(this.functions.nextSong, 500);
      });
    },
    initNewWithFriends: () => {
      this.setState(state => ({
        ...withFriendsInitialState,
        launchStep: 2,
        players: update(state.players, {
          $apply: players => {
            return players.map((player, index) => {
              player.score = 0;
              return player;
            });
          }
        })
      }));
    },
    verifyAutoplay: () => {
      this.state.audios.forEach(audio => {
        audio.load();
      });
      this.functions.allowedToPlay();
    },
    allowedToPlay: () => {
      this.setState(
        { gameLoader: false, isAllowedToPlay: true },
        this.functions.playSong
      );
    },
    launchGame: event => {
      event.preventDefault();
      this.setState({ gameInitialization: false, gameLoader: true }, () => {
        updateWithFriendsStartStatistic(
          this.props.currentUserId,
          this.state.players.length,
          this.state.playlistId
        );
        getPlaylist(this.state.playlistId).then(playlist => {
          canAutoplay.audio().then(async ({ result }) => {
            let songs = [];
            let audios = [];
            await Promise.all(
              playlist.map(song => {
                return fetch(song.preview)
                  .then(() => {
                    return { song: song, audio: new Audio(song.preview) };
                  })
                  .catch(() => {
                    return null;
                  });
              })
            ).then(tracksArray => {
              tracksArray.forEach(track => {
                if (track) {
                  songs.push(track.song);
                  audios.push(track.audio);
                }
              });
            });
            this.setState(
              {
                songs: songs,
                isAllowedToPlay: result,
                gameLoader: result !== false,
                audios: audios
              },
              () => {
                if (this.state.isAllowedToPlay) {
                  this.state.audios.forEach(audio => {
                    audio.load();
                  });
                  this.initGameTimeout = setTimeout(
                    this.functions.allowedToPlay,
                    TWO_SECONDS
                  );
                }
              }
            );
          });
        });
      });
    },
    playSong: () => {
      const currentSongId = this.state.currentSongId;
      if (this.state.songs[currentSongId]) {
        const currentAudio = this.state.audios[currentSongId];
        const songStartTimestamp = newTimestamp();
        this.setState({ currentAudio: currentAudio }, () => {
          clearInterval(this.timeRemainingIndicatorInterval);
          playAudioWithFadeSoundEffect(
            currentAudio,
            songStartTimestamp,
            FIVE_SECONDS,
            this.functions.setTimeRemainingIndicator,
            () => setTimeout(this.functions.assignScores, 500)
          );
        });
      }
    },
    assignScores: () => {
      this.setState({ assignScores: true });
    },
    nextSong: () => {
      const currentSongId = this.state.currentSongId;
      this.setState(
        state => {
          return {
            currentSongId: state.currentSongId + 1,
            currentAudio: null
          };
        },
        () => {
          if (this.state.songs[currentSongId + 1]) {
            this.nextSongTimeout = setTimeout(
              this.functions.playSong,
              TWO_SECONDS
            );
          } else {
            this.functions.endGameBehavior();
          }
        }
      );
    },
    setTimeRemainingIndicator: (maxSeconds, currentTime) => {
      this.setState(
        { timeRemainingIndicator: Math.round(maxSeconds - currentTime) },
        () => {
          this.timeRemainingIndicatorInterval = setInterval(() => {
            if (this.state.timeRemainingIndicator > 0) {
              this.setState(state => ({
                timeRemainingIndicator: state.timeRemainingIndicator - 1
              }));
            } else {
              clearInterval(this.timeRemainingIndicatorInterval);
              this.setState({ timeRemainingIndicator: null });
            }
          }, 1000);
        }
      );
    },
    endGameBehavior: (timeout = TWO_SECONDS) => {
      this.endGameBehaviorTimeout = setTimeout(() => {
        updateWithFriendsEndStatistic(this.props.currentUserId);
        this.setState({ displayResult: true });
      }, timeout);
    }
  };

  componentDidMount() {
    updatePageVisitedStatistic(this.props.slug);
    window.addEventListener("beforeunload", this.functions.componentCleanup);
  }

  componentWillUnmount() {
    this.functions.componentCleanup();
    window.removeEventListener("beforeunload", this.functions.componentCleanup);
  }

  render() {
    const {
      gameInitialization,
      launchStep,
      players,
      playlistId,
      gameLoader,
      currentSongId,
      songs,
      timeRemainingIndicator,
      assignScores,
      displayResult,
      isAllowedToPlay
    } = this.state;
    const {
      changeLaunchStep,
      changePlayersNumber,
      changePlayerName,
      selectPlaylist,
      updatePlayersScore,
      launchGame,
      initNewWithFriends,
      verifyAutoplay
    } = this.functions;

    let alreadyPlayedSongsArray = [];
    songs.forEach((song, index) => {
      if (index < currentSongId) {
        alreadyPlayedSongsArray.push(song);
      }
    });

    const playersSortedByScore = players.sort((a, b) => {
      return b.score - a.score;
    });

    let timeRemainingDisplay =
      timeRemainingIndicator > 0 || timeRemainingIndicator !== null;

    return (
      <div className="Wrapper">
        <Dialog show={gameInitialization} size="Small">
          <WithFriendsInit
            launchStep={launchStep}
            players={players}
            playlistId={playlistId}
            leaveGame={() => this.props.history.push("/")}
            changeLaunchStep={changeLaunchStep}
            changePlayersNumber={changePlayersNumber}
            changePlayerName={changePlayerName}
            selectPlaylist={selectPlaylist}
            launchGame={launchGame}
          />
        </Dialog>
        <Dialog show={!isAllowedToPlay} size="Small">
          <GameAutoplay verifyAutoplay={verifyAutoplay} />
        </Dialog>
        <Dialog show={gameLoader} size="Small">
          <GameLoader />
        </Dialog>
        <Dialog show={assignScores} size="Small">
          <WithFriendsAssignScores
            currentSongId={currentSongId}
            songs={songs}
            players={players}
            updatePlayersScore={updatePlayersScore}
          />
        </Dialog>
        <Dialog show={displayResult} size="Medium">
          <GameResult
            players={playersSortedByScore}
            replayAction={initNewWithFriends}
          />
        </Dialog>
        <CSSTransition
          in={timeRemainingDisplay}
          timeout={500}
          classNames="fade"
          unmountOnExit
        >
          <div className="TimeRemaining BigFixed animated infinite pulse">
            <span className="UbuntuMono">{timeRemainingIndicator}</span>
          </div>
        </CSSTransition>
        <div className="RoomResults">
          <div className="RoomResultsBlocks">
            <Scrollbars autoHide autoHeight autoHeightMax={"100vh"}>
              <div className="RoomMusics">
                <label htmlFor="SongsList" className="ListLabel MB2">
                  <span className="TextStyle BebasNeue">
                    Musiques {`(${currentSongId}/${songs.length})`}
                  </span>
                </label>
                <ul id="SongsList" className="RoomSongs">
                  {alreadyPlayedSongsArray.reverse().map((song, index) => (
                    <GameSong key={"song-" + index} song={song} />
                  ))}
                </ul>
              </div>
            </Scrollbars>
          </div>
          <div className="RoomResultsBlocks">
            <Scrollbars autoHide autoHeight autoHeightMax={"100vh"}>
              <div className="RoomRanking">
                <label htmlFor="RankingList" className="ListLabel MB2">
                  <span className="TextStyle BebasNeue">Classement</span>
                </label>
                <ol id="RankingList" className="RoomPlayers">
                  {playersSortedByScore.map((player, index) => (
                    <GamePlayer
                      key={"player-" + index}
                      player={player}
                      position={index}
                    />
                  ))}
                </ol>
              </div>
            </Scrollbars>
          </div>
        </div>
        <Link to="/">
          <button className="Button HoverStyle MT2">
            <span className="BebasNeue">Quitter la partie</span>
          </button>
        </Link>
      </div>
    );
  }
}

WithFriends.propTypes = {
  currentUser: PropTypes.object.isRequired,
  currentUserId: PropTypes.string.isRequired,
  handleLoader: PropTypes.func.isRequired,
  slug: PropTypes.string.isRequired
};

export default withRouter(WithFriends);
