import { fireauth, firebaseApp, firestore, firedatabase } from "./init";

export const usersPresenceUpdater = () => {
  const uid = fireauth.currentUser.uid;
  const userStatusDatabaseRef = firedatabase.ref("/status/" + uid);

  const isOfflineForDatabase = {
    state: "offline",
    lastChanged: firebaseApp.database.ServerValue.TIMESTAMP
  };

  const isOnlineForDatabase = {
    state: "online",
    lastChanged: firebaseApp.database.ServerValue.TIMESTAMP
  };

  const userStatusFirestoreRef = firestore.doc("/users/" + uid);

  // Firestore uses a different server timestamp value, so we'll
  // create two more constants for Firestore state.
  const isOfflineForFirestore = {
    state: "offline",
    lastChanged: firebaseApp.firestore.Timestamp.now()
  };

  const isOnlineForFirestore = {
    state: "online",
    lastChanged: firebaseApp.firestore.Timestamp.now()
  };

  firebaseApp
    .database()
    .ref(".info/connected")
    .on("value", snapshot => {
      if (snapshot.val() === false) {
        // Instead of simply returning, we'll also set Firestore's state
        // to 'offline'. This ensures that our Firestore cache is aware
        // of the switch to 'offline.'
        userStatusFirestoreRef.set(isOfflineForFirestore, { merge: true });
        return;
      }

      userStatusDatabaseRef
        .onDisconnect()
        .set(isOfflineForDatabase)
        .then(() => {
          userStatusDatabaseRef.set(isOnlineForDatabase);

          // We'll also add Firestore set here for when we come online.
          userStatusFirestoreRef.set(isOnlineForFirestore, { merge: true });
        });
    });
};
