import React from "react";
import PropTypes from "prop-types";

class GamePlayer extends React.Component {
  state = {
    animated: false
  };

  functions = {
    animatePlayer: () => {
      this.setState({ animated: true });
      setTimeout(() => {
        this.setState({ animated: false });
      }, 1000);
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.player.score !== prevProps.player.score) {
      this.functions.animatePlayer();
    }
  }

  render() {
    const { animated } = this.state;
    const { player, position } = this.props;

    return (
      <li className={`RoomPlayer PF1 ${animated ? "animated pulse" : ""}`}>
        <span title={"Nom du joueur : " + player.name}>
          {`${position + 1} - ${player.name}`}
        </span>
        <span title={"Score du joueur : " + player.score}>
          {`${player.score} pt${player.score > 1 ? "s" : ""}`}
        </span>
      </li>
    );
  }
}

GamePlayer.propTypes = {
  player: PropTypes.object.isRequired,
  position: PropTypes.number.isRequired
};

export default GamePlayer;
