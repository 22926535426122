import React from "react";
import { withRouter } from "react-router-dom";
import { getUsersRanking } from "../firebase/user";
import { ROOMS } from "../firebase/room";
import {
  capitalize,
  convertCamelCaseStringToClassicString,
  LOADER_MESSAGES
} from "../helpers/utils";
import Loader from "./Loader";
import { CSSTransition } from "react-transition-group";
import { Scrollbars } from "react-custom-scrollbars";
import { updatePageVisitedStatistic } from "../firebase/statistic";
import PropTypes from "prop-types";

class Ranking extends React.Component {
  state = {
    roomsName: ROOMS,
    frequencies: ["best", "global", "monthly", "weekly"],
    rankings: null,
    selectedRoom: "partieRapide",
    selectedFrequency: "best",
    rankingIsReady: false
  };

  functions = {
    setRanking: (roomName, frequency, playersRanking) => {
      frequency = capitalize(frequency);
      this.setState(state => ({
        rankings: {
          ...state.rankings,
          [roomName + frequency]: playersRanking
        }
      }));
    }
  };

  componentDidMount() {
    updatePageVisitedStatistic(this.props.slug);
    this.state.roomsName.forEach(roomName => {
      this.state.frequencies.forEach(frequency => {
        getUsersRanking(roomName, frequency).then(playersRanking => {
          this.functions.setRanking(roomName, frequency, playersRanking);
        });
      });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.rankings &&
      Object.keys(this.state.rankings).length ===
        this.state.roomsName.length * 3 &&
      this.state.rankings !== prevState.rankings
    ) {
      setTimeout(() => {
        this.setState({ rankingIsReady: true });
      }, 2000);
    }
  }

  render() {
    const {
      roomsName,
      frequencies,
      rankings,
      selectedRoom,
      selectedFrequency,
      rankingIsReady
    } = this.state;

    const rankingKey = selectedRoom + capitalize(selectedFrequency);

    let translatedFrequencies = ["top", "annuel", "mensuel", "hebdomadaire"];

    return (
      <div className="Wrapper">
        <h1 className="MainTitle MB2">
          <span className="TextStyle BebasNeue">Classement</span>
        </h1>
        <div>
          <CSSTransition
            in={!rankingIsReady}
            timeout={500}
            classNames="fade"
            unmountOnExit
          >
            <Loader
              message={LOADER_MESSAGES.rankingInProgress}
              isFixed={false}
            />
          </CSSTransition>
          <form action="#" className="RankingForm">
            <label className="Label MB1">
              <span className="LabelText BebasNeue">Playlist</span>
              <span className="SelectWrapper">
                <select
                  className="Select"
                  name="selectPlaylist"
                  value={selectedRoom || "partieRapide"}
                  onChange={event =>
                    this.setState({ selectedRoom: event.target.value })
                  }
                >
                  {roomsName.map((roomName, index) => {
                    return (
                      <option
                        key={`room-name-option-${index}`}
                        value={roomName}
                      >
                        {convertCamelCaseStringToClassicString(roomName)}
                      </option>
                    );
                  })}
                </select>
              </span>
            </label>
            <label className="Label MB1">
              <span className="LabelText BebasNeue">Score</span>
              <span className="SelectWrapper">
                <select
                  className="Select"
                  name="selectFrequency"
                  value={selectedFrequency || "global"}
                  onChange={event =>
                    this.setState({ selectedFrequency: event.target.value })
                  }
                >
                  {frequencies.map((frequency, index) => {
                    return (
                      <option
                        key={`frequency-option-${index}`}
                        value={frequency}
                      >
                        {convertCamelCaseStringToClassicString(
                          translatedFrequencies[index]
                            ? translatedFrequencies[index]
                            : frequency
                        )}
                      </option>
                    );
                  })}
                </select>
              </span>
            </label>
          </form>
          {rankings && (
            <div className="TableContainer Cells3">
              <Scrollbars autoHide autoHeight autoHeightMax={"50vh"}>
                <table>
                  <thead>
                    <tr>
                      <th>Position</th>
                      <th>Pseudo</th>
                      <th>Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rankings[rankingKey] && rankings[rankingKey].length > 0 ? (
                      Object.values(rankings[rankingKey]).map(
                        (player, index) => {
                          return (
                            <tr key={`player-rank-${index}`}>
                              <td>{index + 1}</td>
                              <td>{player.name}</td>
                              <td>
                                {`${player.score} pt${
                                  player.score > 1 ? "s" : ""
                                }`}
                              </td>
                            </tr>
                          );
                        }
                      )
                    ) : (
                      <tr>
                        <td colSpan="3">
                          Il n'y a pas encore de classement associé.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Scrollbars>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Ranking.propTypes = {
  slug: PropTypes.string.isRequired
};

export default withRouter(Ranking);
