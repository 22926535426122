import React from "react";
import lottie from "lottie-web";
import loadingLogo from "../data/loading-logo";
import PropTypes from "prop-types";

class Loader extends React.Component {
  componentDidMount() {
    lottie.loadAnimation({
      container: this.loadingLogoContainer,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: loadingLogo
    });
  }

  render() {
    const { message, isFixed } = this.props;

    return (
      <div className={`Loader ${isFixed ? "Fixed" : ""}`}>
        <span ref={ref => (this.loadingLogoContainer = ref)} />
        <span className="Nickainley">{message}</span>
      </div>
    );
  }
}

Loader.propTypes = {
  message: PropTypes.string.isRequired,
  isFixed: PropTypes.bool.isRequired
};

export default Loader;
