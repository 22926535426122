import React from "react";
import { CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";
import CloseIcon from "../img/closeIcon.svg";

class Dialog extends React.Component {
  functions = {
    escapePressed: event => {
      if (this.props.handleDialogDisplay && event.keyCode === 27) {
        this.props.handleDialogDisplay(false);
      }
    }
  };

  componentDidMount() {
    document.addEventListener("keydown", this.functions.escapePressed);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.functions.escapePressed);
  }

  render() {
    const { show, size, handleDialogDisplay, children } = this.props;

    return (
      <CSSTransition in={show} timeout={500} classNames="fade" unmountOnExit>
        <div className="DialogWrapper">
          <div className="Overlay" />
          <div className={"Dialog " + size}>
            {handleDialogDisplay && (
              <button
                className="CloseDialog"
                title="Fermer"
                onClick={() => handleDialogDisplay(false)}
              >
                <img src={CloseIcon} alt="" aria-hidden={true} />
              </button>
            )}
            <Scrollbars autoHide autoHeight autoHeightMax={"90vh"}>
              <div className="DialogContent">{children}</div>
            </Scrollbars>
          </div>
        </div>
      </CSSTransition>
    );
  }
}

Dialog.propTypes = {
  show: PropTypes.bool.isRequired,
  size: PropTypes.string.isRequired,
  handleDialogDisplay: PropTypes.func,
  children: PropTypes.object.isRequired
};

export default Dialog;
