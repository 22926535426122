import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// Prevent errors due to service worker cache :
// We want to execute the code below as soon as possible,
// before we even attempt to render our application or run any other code.
// This minimizes the chances of our event handler failing to register due to prior errors.
window.addEventListener("error", async err => {
  // Since we are handling the error here, we must make
  // sure we log it into the console nonetheless, otherwise
  // it will be very difficult to understand why your app
  // is crashing.
  console.error(err);

  // If no service worker is available, our work ends here
  // because we don't need to unregister the service worker
  // to make sure the user is able to get a newer version of
  // our application.
  if (!navigator.serviceWorker) {
    return;
  }

  // On development builds of React, error boundaries don't stop
  // errors from bubbling up to the window error handler, so we don't
  // want to execute this code here because it would be unreliable
  // https://github.com/facebook/react/issues/12897#issuecomment-410036991
  if (process.env.NODE_ENV === "production") {
    // We want to run this code only if we detect a new service worker
    // is getting installed or is installed but waiting to be activated.
    // This will make sure we don't run this code on a sane environment
    // that is crashing for an error not related to stale app cache.
    const registration = await navigator.serviceWorker.ready;
    if (registration.installing || registration.waiting) {
      navigator.serviceWorker.ready.then(async registration => {
        await registration.unregister();
        // Once the service worker is unregistered, we can reload
        // the page to let the browser download a fresh copy of our app
        window.location.reload();
      });
    }
  }
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: registration => {
    setWaitingServiceWorker(registration.waiting);
  },
  onWaiting: registrationWaiting => {
    setWaitingServiceWorker(registrationWaiting);
  },
  onSuccess: registration => {
    console.log("App locally cached.");
  }
});

const setWaitingServiceWorker = waitingServiceWorker => {
  if (waitingServiceWorker) {
    waitingServiceWorker.addEventListener("statechange", event => {
      if (event.target.state === "activated") window.location.reload();
    });
    waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
  }
};
