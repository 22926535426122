import React from "react";
import NoConnection from "../img/noConnectionIcon.svg";

class InternetError extends React.Component {
  render() {
    return (
      <div className="InternetError">
        <div>
          <img src={NoConnection} alt="Connexion à internet impossible" />
        </div>
        <span>Vous avez des problèmes de connexion...</span>
      </div>
    );
  }
}

export default InternetError;
