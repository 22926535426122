import React from "react";
import { Link, withRouter } from "react-router-dom";
import {
  ALERT_MESSAGES,
  ALERT_STATUS,
  convertCamelCaseStringToClassicString,
  convertUserScoresObjectToArray,
  LOADER_MESSAGES,
  passwordStrength
} from "../helpers/utils";
import EyeIcon from "../img/passwordVisibleIcon.svg";
import EyeSlashIcon from "../img/passwordHiddenIcon.svg";
import {
  deleteUser,
  resendEmailVerification,
  updateUserName,
  updateUserPassword
} from "../firebase/user";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";
import { updatePageVisitedStatistic } from "../firebase/statistic";

class Account extends React.Component {
  state = {
    currentUser: this.props.currentUser,
    currentUserId: this.props.currentUserId,
    userName: this.props.currentUser ? this.props.currentUser.displayName : "",
    userPassword: "",
    userDelete: "",
    passwordStrength: null,
    passwordIsVisible: false,
    passwordErrorMessage: "",
    deletionErrorMessage: "",
    loaderDisplaying: false,
    loaderMessage: ""
  };

  functions = {
    clickResendEmailVerification: () => {
      resendEmailVerification().then(response => {
        if (response.success) {
          this.props.handleAlert(
            ALERT_MESSAGES.emailVerificationResendSucceed,
            ALERT_STATUS.success
          );
        } else {
          this.props.handleAlert(
            ALERT_MESSAGES.emailVerificationResendFailed,
            ALERT_STATUS.error
          );
        }
      });
    },
    submitUserNameForm: (event, userName) => {
      event.preventDefault();
      userName = userName.replace(/\s/g, "");
      updateUserName(this.props.currentUserId, userName).then(response => {
        if (response.success) {
          this.props.handleAlert(
            ALERT_MESSAGES.nameUpdateSucceed,
            ALERT_STATUS.success
          );
        } else {
          this.props.handleAlert(
            ALERT_MESSAGES.nameUpdateFailed,
            ALERT_STATUS.error
          );
        }
      });
    },
    submitUserPasswordForm: (event, userPassword) => {
      event.preventDefault();
      if (passwordStrength(userPassword) < 2) {
        this.setState({
          passwordErrorMessage:
            "Les mots de passe sécurisés comportent au moins six caractères et une combinaison de chiffres et de lettres."
        });
      } else {
        updateUserPassword(userPassword).then(response => {
          if (response.success) {
            this.props.handleAlert(
              ALERT_MESSAGES.passwordUpdateSucceed,
              ALERT_STATUS.success
            );
          } else {
            this.props.handleAlert(
              ALERT_MESSAGES.passwordUpdateFailed,
              ALERT_STATUS.error
            );
          }
        });
      }
    },
    submitUserDeleteForm: (event, userId) => {
      event.preventDefault();
      if (this.state.userDelete === "Supprimer mon compte") {
        this.props.handleLoader(true, LOADER_MESSAGES.deletionInProgress);
        this.props.unsubscribeUserListener();
        deleteUser(userId).then(() => {
          this.props.resetCurrentUser();
          this.props.handleLoader(false, LOADER_MESSAGES.deletionInProgress);
          this.props.handleAlert(
            ALERT_MESSAGES.deletionSucceed,
            ALERT_STATUS.success
          );
          this.props.history.push("/connexion");
        });
      } else {
        this.setState({
          deletionErrorMessage:
            "Merci de saisir correctement la phrase de suppression !"
        });
      }
    },
    handleInputChange: event => {
      const target = event.target;
      const name = target.name;
      const value = target.value;
      if (name === "userPassword") {
        this.setState({
          passwordStrength: value ? passwordStrength(value) : null
        });
      }
      this.setState({
        [name]: value,
        passwordErrorMessage: "",
        deletionErrorMessage: ""
      });
    }
  };

  componentDidMount() {
    updatePageVisitedStatistic(this.props.slug);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.currentUser !== prevProps.currentUser) {
      this.setState({
        currentUser: this.props.currentUser,
        userName: this.props.currentUser
          ? this.props.currentUser.displayName
          : ""
      });
    }
  }

  render() {
    const {
      currentUser,
      currentUserId,
      userName,
      userPassword,
      userDelete,
      passwordIsVisible,
      passwordStrength,
      passwordErrorMessage,
      deletionErrorMessage
    } = this.state;
    const {
      clickResendEmailVerification,
      submitUserNameForm,
      submitUserPasswordForm,
      submitUserDeleteForm,
      handleInputChange
    } = this.functions;

    let passwordStrengthClass = "";
    switch (passwordStrength) {
      case 0:
        passwordStrengthClass = "VeryWeak";
        break;
      case 1:
        passwordStrengthClass = "Weak";
        break;
      case 2:
        passwordStrengthClass = "Average";
        break;
      case 3:
        passwordStrengthClass = "Strong";
        break;
      case 4:
        passwordStrengthClass = "VeryStrong";
        break;
      default:
        passwordStrengthClass = "";
        break;
    }

    const userScores = currentUser.scores
      ? convertUserScoresObjectToArray(currentUser.scores)
      : null;

    return (
      <div className="Wrapper">
        <h1 className="MainTitle MB2">
          <span className="TextStyle BebasNeue">Mon compte</span>
        </h1>
        <div className="UserScores MB2">
          <h2 className="MainSubTitle BebasNeue MB1">Scores</h2>
          <div className="TableContainer Cells5">
            <Scrollbars autoHide autoHeight autoHeightMax={"50vh"}>
              <table>
                <thead>
                  <tr>
                    <th>Catégorie</th>
                    <th>Top</th>
                    <th>Annuel</th>
                    <th>Mensuel</th>
                    <th>Hebdomadaire</th>
                  </tr>
                </thead>
                <tbody>
                  {userScores ? (
                    userScores.map(score => {
                      return (
                        <tr key={`scores-${score.roomId}`}>
                          <td>
                            {convertCamelCaseStringToClassicString(
                              score.roomId
                            )}
                          </td>
                          <td>
                            {`${score.best} pt${score.best > 1 ? "s" : ""}`}
                          </td>
                          <td>
                            {`${score.global} pt${score.global > 1 ? "s" : ""}`}
                          </td>
                          <td>{`${score.monthly} pt${
                            score.monthly > 1 ? "s" : ""
                          }`}</td>
                          <td>{`${score.weekly} pt${
                            score.weekly > 1 ? "s" : ""
                          }`}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="5">
                        Vous n'avez pas encore jouez de partie
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Scrollbars>
          </div>
          <Link to="/classement">
            <button className="Button HoverStyle MT1">
              <span className="BebasNeue">Voir le classement</span>
            </button>
          </Link>
        </div>
        <div className="UserParams">
          <h2 className="MainSubTitle BebasNeue">Paramètres</h2>
          {currentUser.emailVerified ? (
            <span className="ParamsInfo MB1">
              Pour mettre à jour votre email, merci de nous contacter.
            </span>
          ) : (
            <span className="ParamsInfo MB1">
              Si vous n'avez pas reçu d'email de confirmation ou si celui-ci n'a
              pas fonctionné,
              <button
                className="InTextButton"
                onClick={clickResendEmailVerification}
              >
                cliquez ici.
              </button>
            </span>
          )}
          <div>
            <form
              action="#"
              id="UserNameForm"
              className="AccountForm MXAuto MB1"
              onSubmit={event => submitUserNameForm(event, userName)}
            >
              <label className="Label">
                <span className="LabelText BebasNeue">Nouveau pseudo</span>
                <input
                  className="InputText"
                  type="text"
                  name="userName"
                  value={userName || ""}
                  onChange={event => handleInputChange(event)}
                  required
                />
              </label>
              <button className="Button HoverStyle ML1" type="submit">
                <span className="BebasNeue">Mettre à jour</span>
              </button>
            </form>
          </div>
          <div>
            <form
              action="#"
              id="UserPasswordForm"
              className="AccountForm MXAuto"
              onSubmit={event => submitUserPasswordForm(event, userPassword)}
            >
              <label className="Label IsPasswordAccount">
                <span className="LabelText BebasNeue">
                  Nouveau mot de passe
                </span>
                <input
                  className="InputText Password"
                  type={passwordIsVisible ? "text" : "password"}
                  name="userPassword"
                  value={userPassword || ""}
                  onChange={event => handleInputChange(event)}
                  required
                />
                <button
                  className="PasswordVisibility"
                  title="Afficher / cacher le mot de passe"
                  type="button"
                  onClick={() =>
                    this.setState({ passwordIsVisible: !passwordIsVisible })
                  }
                >
                  <img
                    src={passwordIsVisible ? EyeSlashIcon : EyeIcon}
                    alt=""
                    aria-hidden={true}
                  />
                </button>
                <span className={`PasswordChecker ${passwordStrengthClass}`} />
              </label>
              <button className="Button HoverStyle ML1" type="submit">
                <span className="BebasNeue">Mettre à jour</span>
              </button>
            </form>
            {passwordErrorMessage && (
              <span className="ErrorMessage MT1">{passwordErrorMessage}</span>
            )}
          </div>
        </div>
        <div className="UserDelete MT2 MB2">
          <span className="ParamsInfo MB1">
            Pour supprimer votre compte, merci de saisir "Supprimer mon compte"
            ci-dessous.
          </span>
          <div>
            <form
              action="#"
              id="UserDelete"
              className="AccountForm MXAuto MB1"
              onSubmit={event => submitUserDeleteForm(event, currentUserId)}
            >
              <label className="Label">
                <span className="LabelText BebasNeue">
                  Confirmation de suppression
                </span>
                <input
                  className="InputText"
                  type="text"
                  name="userDelete"
                  value={userDelete || ""}
                  onChange={event => handleInputChange(event)}
                  required
                />
              </label>
              <button className="Button HoverStyle ML1" type="submit">
                <span className="BebasNeue">Supprimer</span>
              </button>
            </form>
            {deletionErrorMessage && (
              <span className="ErrorMessage MT1">{deletionErrorMessage}</span>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Account.propTypes = {
  currentUser: PropTypes.object.isRequired,
  currentUserId: PropTypes.string.isRequired,
  handleLoader: PropTypes.func.isRequired,
  handleAlert: PropTypes.func.isRequired,
  unsubscribeUserListener: PropTypes.func.isRequired,
  resetCurrentUser: PropTypes.func.isRequired,
  slug: PropTypes.string.isRequired
};

export default withRouter(Account);
