import React from "react";
import lottie from "lottie-web";
import loadingLogo from "../data/loading-logo";

class GameLoader extends React.Component {
  componentDidMount() {
    lottie.loadAnimation({
      container: this.loadingLogoContainer,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: loadingLogo
    });
  }

  render() {
    return (
      <div className="CountDown">
        <span ref={ref => (this.loadingLogoContainer = ref)} />
        <span className="CountDownTitle BebasNeue">Préparez-vous !</span>
        <span className="Nickainley">La partie commence bientôt !</span>
      </div>
    );
  }
}

export default GameLoader;
