import React from "react";
import "./css/App.css";
import "./css/Fonts.css";
import "./css/Utils.css";
import "./css/Animation.css";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Connection from "./components/Connection";
import Home from "./components/Home";
import Room from "./components/Room";
import { fireauth, firedatabase, firestore } from "./firebase/init";
import { getUser, userUpdater } from "./firebase/user";
import Loader from "./components/Loader";
import { usersPresenceUpdater } from "./firebase/presence";
import Header from "./components/Header";
import Alert from "./components/Alert";
import Account from "./components/Account";
import Footer from "./components/Footer";
import ContentPage from "./components/ContentPage";
import Legals from "./data/legals";
import Credits from "./data/credits";
import Rules from "./data/rules";
import Releases from "./data/release-notes";
import WithFriends from "./components/WithFriends";
import { CSSTransition } from "react-transition-group";
import Ranking from "./components/Ranking";
import InternetError from "./components/InternetError";
import { updateAuthenticatedUserStatistic } from "./firebase/statistic";
import A2HS from "./components/A2HS";

class App extends React.Component {
  state = {
    loaderDisplaying: true,
    loaderMessage: "Chargement en cours...",
    alertDisplaying: false,
    alertMessage: "",
    alertType: "",
    isOnline: true,
    isOfflineDisplaying: false,
    addToHomeScreenDisplaying: false,
    currentUser: null,
    currentUserId: null,
    appIsReady: false,
    beforeInstallPromptEvent: null
  };

  functions = {
    connectionListener: () => {
      firedatabase.ref(".info/connected").on("value", snap => {
        if (snap.val() === true) {
          this.setState({ isOnline: true });
        } else {
          this.setState({ isOnline: false });
        }
      });
    },
    handleLoader: (isVisible, message) => {
      this.setState(
        state => {
          return { loaderMessage: message ? message : state.loaderMessage };
        },
        () => {
          const timeout = isVisible ? 0 : 1500;
          setTimeout(() => {
            this.setState({ loaderDisplaying: isVisible });
          }, timeout);
        }
      );
    },
    handleAlert: (message, type) => {
      this.setState(
        { alertDisplaying: true, alertMessage: message, alertType: type },
        () => {
          const timeout = 1000 + message.length * 100;
          setTimeout(() => {
            this.setState({ alertDisplaying: false });
          }, timeout);
        }
      );
    },
    handleInternetError: isVisible => {
      this.setState({ isOfflineDisplaying: isVisible });
    },
    handleAddToHomeScreen: accept => {
      this.setState({ addToHomeScreenDisplaying: false });
      if (accept && this.state.beforeInstallPromptEvent !== null) {
        this.state.beforeInstallPromptEvent.prompt();
        this.state.beforeInstallPromptEvent["userChoice"].then(choiceResult => {
          if (choiceResult["outcome"] === "accepted") {
            console.log("User accepted the A2HS prompt");
          } else {
            console.log("User dismissed the A2HS prompt");
          }
          this.setState({ beforeInstallPromptEvent: null });
        });
      }
    },
    userListener: userId => {
      this.unsubscribeUserListener = firestore
        .collection("users")
        .doc(userId)
        .onSnapshot(rawUser => {
          if (rawUser.exists) {
            const user = rawUser.data();
            this.setState({ currentUser: user });
          }
        });
    },
    resetCurrentUser: () => {
      this.setState({ currentUser: null, currentUserId: null });
    }
  };

  componentDidMount() {
    this.functions.connectionListener();
    window.addEventListener("beforeinstallprompt", event => {
      event.preventDefault();
      this.setState({
        beforeInstallPromptEvent: event,
        addToHomeScreenDisplaying: true
      });
    });
    fireauth.onAuthStateChanged(rawUser => {
      if (rawUser) {
        this.setState({ currentUserId: rawUser.uid });
        getUser(rawUser.uid).then(user => {
          if (user) {
            this.setState({ currentUser: user, appIsReady: true }, () => {
              updateAuthenticatedUserStatistic(rawUser);
              usersPresenceUpdater();
              this.functions.handleLoader(false);
              if (rawUser.emailVerified !== user.emailVerified) {
                userUpdater(rawUser.uid, {
                  emailVerified: rawUser.emailVerified
                });
              }
            });
          } else {
            this.setState({ appIsReady: true }, () => {
              this.functions.handleLoader(false);
            });
          }
        });
      } else {
        this.setState({ appIsReady: true }, () => {
          this.functions.handleLoader(false);
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.currentUserId !== prevState.currentUserId &&
      this.state.currentUserId
    ) {
      this.functions.userListener(this.state.currentUserId);
    }
    if (
      this.state.isOnline !== prevState.isOnline &&
      this.state.appIsReady &&
      !this.state.loaderDisplaying
    ) {
      this.functions.handleInternetError(!this.state.isOnline);
    }
  }

  render() {
    const {
      loaderDisplaying,
      loaderMessage,
      alertDisplaying,
      alertMessage,
      alertType,
      isOfflineDisplaying,
      addToHomeScreenDisplaying,
      currentUser,
      currentUserId,
      appIsReady
    } = this.state;
    const {
      handleLoader,
      handleAlert,
      resetCurrentUser,
      handleAddToHomeScreen
    } = this.functions;

    return (
      <div className="App Ubuntu">
        <CSSTransition
          in={alertDisplaying}
          timeout={500}
          classNames="alert"
          unmountOnExit
        >
          <Alert message={alertMessage} type={alertType} />
        </CSSTransition>
        <CSSTransition
          in={loaderDisplaying}
          timeout={500}
          classNames="fade"
          unmountOnExit
        >
          <Loader message={loaderMessage} isFixed={true} />
        </CSSTransition>
        <CSSTransition
          in={isOfflineDisplaying}
          timeout={500}
          classNames="offline"
          unmountOnExit
        >
          <InternetError />
        </CSSTransition>
        {addToHomeScreenDisplaying && (
          <A2HS handleAddToHomeScreen={handleAddToHomeScreen} />
        )}
        {appIsReady && (
          <BrowserRouter>
            <Header
              currentUser={currentUser}
              currentUserId={currentUserId}
              handleLoader={handleLoader}
              handleAlert={handleAlert}
              unsubscribeUserListener={this.unsubscribeUserListener}
              resetCurrentUser={resetCurrentUser}
            />
            <Switch>
              <Route exact path="/connexion">
                <Connection
                  currentUser={currentUser}
                  currentUserId={currentUserId}
                  loaderDisplaying={loaderDisplaying}
                  handleLoader={handleLoader}
                  handleAlert={handleAlert}
                  slug={"connection"}
                />
              </Route>
              <Route exact path="/">
                {currentUser ? (
                  <Home
                    currentUser={currentUser}
                    currentUserId={currentUserId}
                    slug={"home"}
                  />
                ) : (
                  <Redirect exact to="/connexion" />
                )}
              </Route>
              <Route exact path="/partie/:roomId">
                {currentUser ? (
                  <Room
                    currentUser={currentUser}
                    currentUserId={currentUserId}
                    handleLoader={handleLoader}
                    handleAlert={handleAlert}
                    slug={"room"}
                  />
                ) : (
                  <Redirect exact to="/connexion" />
                )}
              </Route>
              <Route exact path="/entre-potes">
                {currentUser ? (
                  <WithFriends
                    currentUser={currentUser}
                    currentUserId={currentUserId}
                    handleLoader={handleLoader}
                    slug={"withFriends"}
                  />
                ) : (
                  <Redirect exact to="/connexion" />
                )}
              </Route>
              <Route exact path="/account">
                {currentUser && !currentUser.isAnonymous ? (
                  <Account
                    currentUser={currentUser}
                    currentUserId={currentUserId}
                    handleLoader={handleLoader}
                    handleAlert={handleAlert}
                    unsubscribeUserListener={this.unsubscribeUserListener}
                    resetCurrentUser={resetCurrentUser}
                    slug={"account"}
                  />
                ) : (
                  <Redirect exact to={currentUser ? "/" : "/connexion"} />
                )}
              </Route>
              <Route exact path="/classement">
                <Ranking slug={"ranking"} />
              </Route>
              <Route exact path="/regles-du-jeu">
                <ContentPage content={Rules} slug={"rules"} />
              </Route>
              <Route exact path="/mentions-legales">
                <ContentPage content={Legals} slug={"legals"} />
              </Route>
              <Route exact path="/credits">
                <ContentPage content={Credits} slug={"credits"} />
              </Route>
              <Route exact path="/notes-de-version">
                <ContentPage content={Releases} slug={"releaseNotes"} />
              </Route>
              <Route>
                <Redirect exact to={currentUser ? "/" : "/connexion"} />
              </Route>
            </Switch>
            <Footer />
          </BrowserRouter>
        )}
      </div>
    );
  }
}

export default App;
