import React from "react";
import PropTypes from "prop-types";
import MusicIcon from "../img/musicIcon.svg";
import ArtistIcon from "../img/artistIcon.svg";

class GameSong extends React.Component {
  render() {
    const { song } = this.props;

    return (
      <li className="RoomSong">
        <img
          className="RoomSongCover"
          src={song.cover}
          alt={"Illustration de " + song.title + " de " + song.artist}
        />
        <div className="RoomSongInfos">
          <div className="RoomSongInfo">
            <span className="AnswerIcon MR0">
              <img src={MusicIcon} alt="" aria-hidden={true} />
            </span>
            <span className="RoomSongText" title={song.title}>
              {song.title}
            </span>
          </div>
          <div className="RoomSongInfo">
            <span className="AnswerIcon MR0">
              <img src={ArtistIcon} alt="" aria-hidden={true} />
            </span>
            <span className="RoomSongText" title={song.artist}>
              {song.artist}
            </span>
          </div>
        </div>
      </li>
    );
  }
}

GameSong.propTypes = {
  song: PropTypes.object.isRequired
};

export default GameSong;
