import {
  fireauth,
  firestore,
  firedatabase,
  fireEmailAuthProvider,
  googletranslate,
  firebaseApp
} from "./init";
import { usersPresenceUpdater } from "./presence";

const usersCollection = firestore.collection("users");

export const signInAnonymousUser = userName => {
  const displayName = userName === "" ? "User" + Date.now() : userName;
  return fireauth
    .signInAnonymously()
    .then(async userCredential => {
      await fireauth.currentUser.updateProfile({
        displayName: displayName
      });
      return userCredential;
    })
    .then(userCredential => {
      return setUser(userCredential.user.uid, {
        currentRoom: null,
        displayName: displayName,
        email: userCredential.user.email,
        emailVerified: userCredential.user.emailVerified,
        isAnonymous: userCredential.user.isAnonymous
      });
    })
    .then(() => {
      usersPresenceUpdater();
      return { success: true, error: null };
    })
    .catch(error => {
      console.error("Error : ", error);
      return { success: false, error: error };
    });
};

export const signInUser = (userEmail, userPassword) => {
  return fireauth
    .signInWithEmailAndPassword(userEmail, userPassword)
    .then(async userCredential => {
      await userUpdater(userCredential.user.uid, {
        currentRoom: null
      });
      return { success: true, error: null };
    })
    .catch(async error => {
      error = await googletranslate(error.message, "fr");
      return { success: false, error: error };
    });
};

export const updateUserName = (currentUserId, userName) => {
  const displayName = userName === "" ? "User" + Date.now() : userName;
  return fireauth.currentUser
    .updateProfile({
      displayName: displayName
    })
    .then(async () => {
      await userUpdater(currentUserId, {
        displayName: displayName
      });
      return { success: true, error: null };
    })
    .catch(error => {
      console.error("Error : ", error);
      return { success: false, error: error };
    });
};

export const updateUserPassword = userPassword => {
  return fireauth.currentUser
    .updatePassword(userPassword)
    .then(() => {
      return { success: true, error: null };
    })
    .catch(error => {
      console.error("Error : ", error);
      return { success: false, error: error };
    });
};

export const resendEmailVerification = () => {
  return fireauth.currentUser
    .sendEmailVerification()
    .then(() => {
      return { success: true, error: null };
    })
    .catch(error => {
      return { success: false, error: error };
    });
};

export const signUpNewUser = (userName, userEmail, userPassword) => {
  const displayName = userName === "" ? "User" + Date.now() : userName;
  return fireauth
    .createUserWithEmailAndPassword(userEmail, userPassword)
    .then(async data => {
      await fireauth.currentUser.updateProfile({
        displayName: displayName
      });
      return data;
    })
    .then(data => {
      return setUser(data.user.uid, {
        currentRoom: null,
        displayName: displayName,
        email: data.user.email,
        emailVerified: data.user.emailVerified,
        isAnonymous: data.user.isAnonymous
      });
    })
    .then(async () => {
      usersPresenceUpdater();
      await fireauth.currentUser.sendEmailVerification();
      return { success: true, error: null };
    })
    .catch(async error => {
      error = await googletranslate(error.message, "fr");
      return { success: false, error: error };
    });
};

export const signUpAnonymousUser = (userName, userEmail, userPassword) => {
  const credential = fireEmailAuthProvider.credential(userEmail, userPassword);
  return fireauth.currentUser
    .linkWithCredential(credential)
    .then(userCredential => {
      const user = userCredential.user;
      return setUser(user.uid, {
        displayName: user.displayName,
        email: user.email,
        emailVerified: user.emailVerified,
        isAnonymous: user.isAnonymous
      });
    })
    .then(() => {
      usersPresenceUpdater();
      return { success: true, error: null };
    })
    .catch(async error => {
      error = await googletranslate(error.message, "fr");
      return { success: false, error: error };
    });
};

export const signOutUser = currentUserId => {
  return userUpdater(currentUserId, {
    currentRoom: null
  }).then(() => {
    return firedatabase
      .ref("status/" + currentUserId)
      .set({
        state: "offline",
        lastChanged: firebaseApp.database.ServerValue.TIMESTAMP
      })
      .then(() => {
        return fireauth
          .signOut()
          .then(() => null)
          .catch(error => {
            return error;
          });
      });
  });
};

export const setUser = (userId, userData) => {
  return usersCollection
    .doc(userId)
    .set(userData, { merge: true })
    .then(() => null)
    .catch(error => {
      console.error("Error : ", error);
    });
};

export const getUser = userId => {
  return usersCollection
    .doc(userId)
    .get()
    .then(user => {
      if (user.exists) {
        return user.data();
      } else {
        return null;
      }
    })
    .catch(error => {
      console.error("Error : ", error);
    });
};

export const userUpdater = (userId, data) => {
  return usersCollection
    .doc(userId)
    .update(data)
    .then(() => null)
    .catch(error => {
      console.error("Error : ", error);
    });
};

export const deleteUser = userId => {
  return usersCollection
    .doc(userId)
    .delete()
    .then(() => {
      return firedatabase
        .ref("status/" + userId)
        .remove()
        .then(() => {
          return fireauth.currentUser
            .delete()
            .then(() => {
              console.log("User successfully deleted!");
            })
            .catch(error => {
              console.error("Error : ", error);
            });
        })
        .catch(error => {
          console.error("Error : ", error);
        });
    })
    .catch(error => {
      console.error("Error : ", error);
    });
};

export const resetUserPassword = userEmail => {
  return fireauth
    .sendPasswordResetEmail(userEmail)
    .then(() => null)
    .catch(error => {
      return googletranslate(error.message, "fr");
    });
};

export const getUsersRanking = (roomId, frequency) => {
  return usersCollection
    .orderBy("scores." + roomId + "." + frequency, "desc")
    .limit(1000)
    .get()
    .then(querySnapshot => {
      let playersScoresArray = [];
      querySnapshot.forEach(rawPlayer => {
        const player = rawPlayer.data();
        const playerScore = {
          name: player.displayName,
          score: player.scores[roomId][frequency]
        };
        playersScoresArray.push(playerScore);
      });
      return playersScoresArray;
    });
};
