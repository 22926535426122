import React from "react";
import { withRouter } from "react-router-dom";
import {
  resetUserPassword,
  signInUser,
  signUpAnonymousUser,
  signUpNewUser
} from "../firebase/user";
import EyeIcon from "../img/passwordVisibleIcon.svg";
import EyeSlashIcon from "../img/passwordHiddenIcon.svg";
import {
  ALERT_MESSAGES,
  ALERT_STATUS,
  LOADER_MESSAGES,
  passwordStrength
} from "../helpers/utils";
import PropTypes from "prop-types";

class Authentication extends React.Component {
  state = {
    userName: this.props.currentUser ? this.props.currentUser.displayName : "",
    userEmail: "",
    userPassword: "",
    passwordStrength: null,
    passwordIsVisible: false,
    convertAccount: !!this.props.currentUser,
    errorMessage: ""
  };

  functions = {
    submitSignUpForm: (event, userName, userEmail, userPassword) => {
      event.preventDefault();
      userName = userName.replace(/\s/g, "");
      if (passwordStrength(userPassword) < 2) {
        this.setState({
          errorMessage:
            "Les mots de passe sécurisés comportent au moins six caractères et une combinaison de chiffres et de lettres."
        });
      } else {
        this.props.handleLoader(true, LOADER_MESSAGES.signUpInProgress);
        if (this.state.convertAccount) {
          signUpAnonymousUser(userName, userEmail, userPassword).then(
            response => {
              this.functions.connectionResponseBehavior(response, "signUp");
            }
          );
        } else {
          if (this.props.currentUser) {
            this.props.unsubscribeUserListener();
          }
          signUpNewUser(userName, userEmail, userPassword).then(response => {
            this.functions.connectionResponseBehavior(response, "signUp");
          });
        }
      }
    },
    submitSignInForm: (event, userEmail, userPassword) => {
      event.preventDefault();
      this.props.handleLoader(true, LOADER_MESSAGES.signInInProgress);
      signInUser(userEmail, userPassword).then(response => {
        this.functions.connectionResponseBehavior(response, "signIn");
      });
    },
    resetPasswordForm: (event, userEmail) => {
      event.preventDefault();
      resetUserPassword(userEmail).then(error => {
        if (error !== null) {
          this.setState({ errorMessage: error });
        } else {
          this.props.handleDialogDisplay(false);
          this.props.handleAlert(
            ALERT_MESSAGES.passwordResetSucceed,
            ALERT_STATUS.success
          );
        }
      });
    },
    handleInputChange: event => {
      const target = event.target;
      const name = target.name;
      const value = name === "convertAccount" ? target.checked : target.value;
      if (name === "userPassword") {
        this.setState({
          passwordStrength: value ? passwordStrength(value) : null
        });
      }
      this.setState({ [name]: value, errorMessage: "" });
    },
    connectionResponseBehavior: (response, connectionType) => {
      if (response.success) {
        this.props.handleDialogDisplay(false);
        this.props.handleAlert(
          connectionType === "signIn"
            ? ALERT_MESSAGES.signInSucceed
            : ALERT_MESSAGES.signUpSucceed,
          ALERT_STATUS.success
        );
        this.props.history.push("/");
      } else {
        this.setState({ errorMessage: response.error });
        this.props.handleAlert(
          connectionType === "signIn"
            ? ALERT_MESSAGES.signInFailed
            : ALERT_MESSAGES.signUpFailed,
          ALERT_STATUS.error
        );
      }
      this.props.handleLoader(false);
    }
  };

  render() {
    const {
      userName,
      userEmail,
      userPassword,
      convertAccount,
      errorMessage,
      passwordStrength,
      passwordIsVisible
    } = this.state;
    const { currentUser, handleDialogDisplay, authFormType } = this.props;
    const {
      submitSignUpForm,
      submitSignInForm,
      handleInputChange,
      resetPasswordForm
    } = this.functions;

    let passwordStrengthClass = "";
    switch (passwordStrength) {
      case 0:
        passwordStrengthClass = "VeryWeak";
        break;
      case 1:
        passwordStrengthClass = "Weak";
        break;
      case 2:
        passwordStrengthClass = "Average";
        break;
      case 3:
        passwordStrengthClass = "Strong";
        break;
      case 4:
        passwordStrengthClass = "VeryStrong";
        break;
      default:
        passwordStrengthClass = "";
        break;
    }

    return (
      <>
        {authFormType === "signUp" && (
          <form
            action="#"
            id="SignUpForm"
            className="FullWidth"
            onSubmit={event =>
              submitSignUpForm(event, userName, userEmail, userPassword)
            }
          >
            <label className="Label MB1">
              <span className="LabelText BebasNeue">Pseudo</span>
              <input
                className="InputText"
                type="text"
                name="userName"
                value={userName || ""}
                onChange={event => handleInputChange(event)}
                required
              />
            </label>
            <label className="Label MB1">
              <span className="LabelText BebasNeue">Email</span>
              <input
                className="InputText"
                type="email"
                name="userEmail"
                value={userEmail || ""}
                onChange={event => handleInputChange(event)}
                required
              />
            </label>
            <label className="Label MB1">
              <span className="LabelText BebasNeue">Mot de passe</span>
              <input
                className="InputText Password"
                type={passwordIsVisible ? "text" : "password"}
                name="userPassword"
                value={userPassword || ""}
                onChange={event => handleInputChange(event)}
                required
              />
              <button
                className="PasswordVisibility"
                title="Afficher / cacher le mot de passe"
                type="button"
                onClick={() =>
                  this.setState({ passwordIsVisible: !passwordIsVisible })
                }
              >
                <img
                  src={passwordIsVisible ? EyeSlashIcon : EyeIcon}
                  alt=""
                  aria-hidden={true}
                />
              </button>
              <span className={`PasswordChecker ${passwordStrengthClass}`} />
            </label>
            {currentUser && (
              <label className="Label WhiteSpaceNoWrap MB1">
                <input
                  className="InputCheckbox"
                  type="checkbox"
                  name="convertAccount"
                  checked={convertAccount}
                  onChange={event => handleInputChange(event)}
                />
                <span className="LabelCheckbox ML0">
                  Convertir mon compte temporaire et conserver ma progression
                </span>
              </label>
            )}
            <button className="Button HoverStyle MT1" type="submit">
              <span className="BebasNeue">Créer mon compte</span>
            </button>
            {errorMessage && (
              <span className="ErrorMessage MT1">{errorMessage}</span>
            )}
          </form>
        )}
        {authFormType === "signIn" && (
          <form
            action="#"
            id="SignInForm"
            className="FullWidth"
            onSubmit={event => submitSignInForm(event, userEmail, userPassword)}
          >
            <label className="Label MB1">
              <span className="LabelText BebasNeue">Email</span>
              <input
                className="InputText"
                type="email"
                name="userEmail"
                value={userEmail || ""}
                onChange={event => handleInputChange(event)}
                required
              />
            </label>
            <label className="Label MB1">
              <span className="LabelText BebasNeue">Mot de passe</span>
              <input
                className="InputText Password"
                type={passwordIsVisible ? "text" : "password"}
                name="userPassword"
                value={userPassword || ""}
                onChange={event => handleInputChange(event)}
                required
              />
              <button
                className="PasswordVisibility"
                title="Afficher / cacher le mot de passe"
                type="button"
                onClick={() =>
                  this.setState({ passwordIsVisible: !passwordIsVisible })
                }
              >
                <img
                  src={passwordIsVisible ? EyeSlashIcon : EyeIcon}
                  alt=""
                  aria-hidden={true}
                />
              </button>
            </label>
            <button className="Button HoverStyle MT1" type="submit">
              <span className="BebasNeue">Connexion</span>
            </button>
            <button
              className="ForgotPasswordButton MXAuto MT1"
              type="button"
              onClick={() => handleDialogDisplay(true, "passwordReset")}
            >
              J'ai oublié mon mot de passe
            </button>
            {errorMessage && (
              <span className="ErrorMessage MT1">{errorMessage}</span>
            )}
          </form>
        )}
        {authFormType === "passwordReset" && (
          <form
            action="#"
            id="PasswordResetForm"
            className="FullWidth"
            onSubmit={event => resetPasswordForm(event, userEmail)}
          >
            <label className="Label MB1">
              <span className="LabelText BebasNeue">Email</span>
              <input
                className="InputText"
                type="email"
                name="userEmail"
                value={userEmail || ""}
                onChange={event => handleInputChange(event)}
                required
              />
            </label>
            <button className="Button HoverStyle MT1" type="submit">
              <span className="BebasNeue">Réinitialiser mon mot de passe</span>
            </button>
            {errorMessage && (
              <span className="ErrorMessage MT1">{errorMessage}</span>
            )}
          </form>
        )}
      </>
    );
  }
}

Authentication.propTypes = {
  currentUser: PropTypes.object,
  authFormType: PropTypes.string,
  handleDialogDisplay: PropTypes.func.isRequired,
  handleLoader: PropTypes.func.isRequired,
  handleAlert: PropTypes.func.isRequired,
  unsubscribeUserListener: PropTypes.func
};

export default withRouter(Authentication);
