import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { updatePageVisitedStatistic } from "../firebase/statistic";

class ContentPage extends React.Component {
  componentDidMount() {
    updatePageVisitedStatistic(this.props.slug);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.slug !== this.props.slug) {
      updatePageVisitedStatistic(this.props.slug);
    }
  }

  render() {
    const { content } = this.props;

    return (
      <div className="Wrapper">
        {content.map((block, index) => {
          return (
            <div key={`block-${index}`} className="ContentBlock">
              <h1>{block.title}</h1>
              <p>{block.text}</p>
            </div>
          );
        })}
      </div>
    );
  }
}

ContentPage.propTypes = {
  content: PropTypes.array.isRequired,
  slug: PropTypes.string.isRequired
};

export default withRouter(ContentPage);
