import { firestore } from "./init";
import { determineGameEndTimestamp, newTimestamp } from "../helpers/utils";
import noMatch from "../sounds/noMatch.mp3";
import oneMatch from "../sounds/oneMatch.mp3";
import twoMatches from "../sounds/twoMatches.mp3";

export const withFriendsInitialState = {
  gameInitialization: true,
  displayResult: false,
  assignScores: false,
  currentSongId: 0,
  currentAudio: null,
  isAllowedToPlay: true,
  timeRemainingIndicator: null,
  gameLoader: false,
  songs: [],
  audios: []
};

export const initialSongPropositionMatchesClass = {
  input: "",
  title: "",
  artist: ""
};

export const roomInitialState = {
  timeRemainingIndicator: null,
  songInputValue: "",
  allSongsStartTimestamp: [],
  gameStartTimestamp: null,
  artistScore: null,
  titleScore: null,
  score: 0,
  newRecord: false,
  songPropositionMatchesClass: initialSongPropositionMatchesClass,
  currentSongId: 0,
  players: {},
  songs: [],
  displayResult: false,
  gameLoader: true,
  audios: [],
  currentAudio: null,
  isAllowedToPlay: true,
  roomListening: false,
  uiAudios: {
    oneMatch: new Audio(oneMatch),
    twoMatches: new Audio(twoMatches),
    noMatch: new Audio(noMatch)
  }
};

export const ROOMS = [
  "partieRapide",
  "rockYourDay",
  "rapBangers",
  "popJoy",
  "bleuBlancHits"
];

export const roomsCollection = firestore.collection("rooms");

export const getRoom = (roomId, currentUserId, playerName, playerScore) => {
  const currentPlayer = {
    name: playerName,
    score: playerScore,
    isConnected: true
  };
  return roomsCollection
    .doc(roomId)
    .get()
    .then(rawRoom => {
      if (rawRoom.exists) {
        const room = rawRoom.data();
        if (room.isGameIsOver) {
          return null;
        } else {
          const currentTimestamp = newTimestamp();
          const gameEndTimestamp = determineGameEndTimestamp(
            room.songs,
            room.gameStartTimestamp
          );
          if (room.gameStartTimestamp && gameEndTimestamp > currentTimestamp) {
            currentPlayer.score =
              room.players[currentUserId] && room.players[currentUserId].score
                ? room.players[currentUserId].score
                : currentPlayer.score;
            return roomUpdater(roomId, {
              ["players." + currentUserId]: currentPlayer
            })
              .then(() => {
                room.players[currentUserId] = currentPlayer;
                return room;
              })
              .catch(error => {
                console.error("Error : ", error);
              });
          } else {
            return roomUpdater(roomId, {
              players: {
                [currentUserId]: currentPlayer
              },
              gameStartTimestamp: currentTimestamp,
              currentSongId: 0
            })
              .then(() => {
                room.players[currentUserId] = currentPlayer;
                room.gameStartTimestamp = currentTimestamp;
                room.currentSongId = 0;
                return room;
              })
              .catch(error => {
                console.error("Error : ", error);
              });
          }
        }
      } else {
        return null;
      }
    })
    .catch(error => {
      console.error("Error : ", error);
    });
};

export const roomUpdater = (roomId, data) => {
  return firestore
    .collection("rooms")
    .doc(roomId)
    .update(data)
    .then(() => null)
    .catch(error => {
      console.error("Error : ", error);
    });
};
