import React from "react";
import { withRouter } from "react-router-dom";
import QuickPlaySvg from "../img/quickPlay.svg";
import WithFriendSvg from "../img/withFriends.svg";
import RockYourDaySvg from "../img/rockYourDay.svg";
import RapBangersSvg from "../img/rapBangers.svg";
import PopJoySvg from "../img/popJoy.svg";
import BleuBlancHitsSvg from "../img/bleuBlancHits.svg";
import { roomsCollection } from "../firebase/room";
import HomeCategory from "./HomeCategory";
import PropTypes from "prop-types";
import { updatePageVisitedStatistic } from "../firebase/statistic";

class Home extends React.Component {
  state = {
    currentUser: this.props.currentUser,
    roomsPlayers: {}
  };

  functions = {
    roomsListener: () => {
      this.unsubscribeRoomsListener = roomsCollection.onSnapshot(
        querySnapshot => {
          querySnapshot.forEach(room => {
            const data = room.data();
            const roomPlayersConnected = Object.values(data.players).filter(
              player => player.isConnected
            );
            this.setState(state => ({
              roomsPlayers: {
                ...state.roomsPlayers,
                [room.id]: roomPlayersConnected.length
              }
            }));
          });
        }
      );
    }
  };

  componentDidMount() {
    updatePageVisitedStatistic(this.props.slug);
    this.functions.roomsListener();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.currentUser !== prevProps.currentUser) {
      this.setState({ currentUser: this.props.currentUser });
    }
  }

  componentWillUnmount() {
    this.unsubscribeRoomsListener();
  }

  render() {
    const { roomsPlayers } = this.state;

    return (
      <div className="Wrapper HomeWrapper">
        <div className="HomeCategories">
          <HomeCategory
            roomPlayersCount={roomsPlayers["partieRapide"]}
            pathName="/partie/partieRapide"
            roomId="partieRapide"
            isSoon={false}
            isHighlighted={true}
            roomSvg={QuickPlaySvg}
            roomName="Partie rapide"
            roomDescription="Le meilleur moyen de s'éclater rapidement&nbsp;!"
          />
          <HomeCategory
            roomPlayersCount={null}
            pathName="/entre-potes"
            roomId=""
            isSoon={false}
            isHighlighted={true}
            roomSvg={WithFriendSvg}
            roomName="Entre potes"
            roomDescription="Idéal pour les soirées ou les longues après-midi ennuyeuses... #bloqués"
          />
          <HomeCategory
            roomPlayersCount={roomsPlayers["rockYourDay"]}
            pathName="/partie/rockYourDay"
            roomId="rockYourDay"
            isSoon={false}
            isHighlighted={false}
            roomSvg={RockYourDaySvg}
            roomName="Rock your day"
            roomDescription="Révèle la rockstar qui est en toi&nbsp;!"
          />
          <HomeCategory
            roomPlayersCount={roomsPlayers["rapBangers"]}
            pathName="/partie/rapBangers"
            roomId="rapBangers"
            isSoon={false}
            isHighlighted={false}
            roomSvg={RapBangersSvg}
            roomName="Rap Bangers"
            roomDescription="Impose ta street credibility&nbsp;!"
          />
          <HomeCategory
            roomPlayersCount={roomsPlayers["popJoy"]}
            pathName="/partie/popJoy"
            roomId="popJoy"
            isSoon={false}
            isHighlighted={false}
            roomSvg={PopJoySvg}
            roomName="Pop Joy"
            roomDescription="Prouve que tu es une vraie popstar&nbsp;!"
          />
          <HomeCategory
            roomPlayersCount={roomsPlayers["bleuBlancHits"]}
            pathName="/partie/bleuBlancHits"
            roomId="bleuBlancHits"
            isSoon={false}
            isHighlighted={false}
            roomSvg={BleuBlancHitsSvg}
            roomName="Bleu Blanc Hits"
            roomDescription="Cocorico, vas y, fais ton coq&nbsp;!"
          />
        </div>
      </div>
    );
  }
}

Home.propTypes = {
  currentUser: PropTypes.object.isRequired,
  currentUserId: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired
};

export default withRouter(Home);
