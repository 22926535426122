import React from "react";
import { Link, withRouter } from "react-router-dom";

class Footer extends React.Component {
  render() {
    return (
      <footer className="AppFooter">
        <ul>
          <li>
            <Link to="/classement">Classement</Link>
          </li>
          <li>
            <Link to="/regles-du-jeu">Règles du jeu</Link>
          </li>
          <li>
            <Link to="/mentions-legales">Mentions légales</Link>
          </li>
          <li>
            <Link to="/credits">Crédits</Link>
          </li>
          <li>
            <Link to="/notes-de-version">Notes de version</Link>
          </li>
        </ul>
      </footer>
    );
  }
}

export default withRouter(Footer);
