import React from "react";
import PropTypes from "prop-types";
import CloseIcon from "../img/closeIcon.svg";

class A2HS extends React.Component {
  render() {
    return (
      <div className="A2HS">
        <p>
          Vous aimez That's my Jam ? Retrouvez le facilement en l'ajoutant comme
          application !
        </p>
        <button
          className="Button HoverStyle MR1"
          onClick={() => this.props.handleAddToHomeScreen(true)}
        >
          <span className="BebasNeue">Ajouter à l'écran d'accueil</span>
        </button>
        <button
          className="CloseDialog"
          title="Fermer"
          onClick={() => this.props.handleAddToHomeScreen(false)}
        >
          <img src={CloseIcon} alt="" aria-hidden={true} />
        </button>
      </div>
    );
  }
}

A2HS.propTypes = {
  handleAddToHomeScreen: PropTypes.func.isRequired
};

export default A2HS;
