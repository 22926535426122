import React from "react";
import { convertPlayersObjectToArray } from "../helpers/utils";
import { Link } from "react-router-dom";
import GamePlayer from "./GamePlayer";
import { ReactSVG } from "react-svg";
import ResultsSvg from "../img/results.svg";
import PropTypes from "prop-types";

class GameResult extends React.Component {
  render() {
    const { players, replayAction, newRecord } = this.props;

    let playersArray = Array.isArray(players)
      ? players
      : convertPlayersObjectToArray(players);

    return (
      <div className="RoomOverRanking">
        <ReactSVG src={ResultsSvg} className="RoomSvg MB2" />
        <div className="RoomRanking FullWidth">
          <label htmlFor="RankingList" className="ListLabel MB2">
            <span className="TextStyle BebasNeue">Résultats</span>
          </label>
          {newRecord && (
            <p>Vous avez battu votre record personnel, félicitations !</p>
          )}
          <ol id="RankingList" className="RoomPlayers">
            {playersArray.map((player, index) => (
              <GamePlayer
                key={"result-player-" + index}
                player={player}
                position={index}
              />
            ))}
          </ol>
        </div>
        <div className="FullButtons MT2">
          <button
            className="Button HoverStyle"
            type="button"
            onClick={() => replayAction()}
          >
            <span className="BebasNeue">Rejouer</span>
          </button>
          <Link className="Button HoverStyle ML2" to="/">
            <span className="BebasNeue">Quitter</span>
          </Link>
        </div>
      </div>
    );
  }
}

GameResult.propTypes = {
  players: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.array.isRequired
  ]),
  newRecord: PropTypes.bool,
  replayAction: PropTypes.func.isRequired
};

export default GameResult;
